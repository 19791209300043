/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import HidePassword from '../../images/hide-password.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

import { AuthSignup } from '../../services/auth';
import SocialLogin from '../common/SocialLogin';
import { FormControl } from '@mui/material';

function SignUp() {

  const nav = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  let [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false)
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    password: ''
  });
  async function signUp() {

    let regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    if (formState.name === '') {
      toast.error('Name cannot be empty');
    } else if (formState.email === '') {
      toast.error('Email cannot be empty');
    } else if (regex.test(formState.email) === false) {
      toast.error('Invalid email');
    } else if (formState.phone === '' || typeof formState.phone === 'undefined') {
      toast.error('Phone number cannot be empty');
    } else if (formState.password === '') {
      toast.error('Password cannot be empty');
    } else if (formState.password.length < '8') {
      toast.error('Password must be atleast 8 characters');
    } else {
      if (loading) {
        return;
      }
      setLoading((s) => !s);
      try {

        const params = {
          name: formState.name,
          email: formState.email,
          password: formState.password,
          phone_number: formState.phone.replace(/\+/g, '')
        }
        
        try {
          const phone = (formState.phone.startsWith('+') ? formState.phone : `+${formState.phone}`)

          if (validatePhoneNumberLength(phone) === 'TOO_SHORT') {
            toast.error('Please enter valid phone number');
            return false
          }

          const parsedNumber = parsePhoneNumber(phone);
          if (parsedNumber) {
            params.phone_number = parsedNumber?.nationalNumber
            params.country_code = parsedNumber?.country || 'US'
            
            if (parsedNumber?.nationalNumber === "") {
              toast.error('Please enter valid phone number');
              return false
            }
          }
        } catch (e) {
          console.error(e);
        }
      
        AuthSignup(params, (response) => {
          setLoading((s) => !s);
          if (response && response.success) {
            nav('/verificationcode', { state: { username: formState.email } });
          } else {
            const errorData = response.error?.data;
            if (errorData && errorData.phone_number && errorData.phone_number.length > 0) {
              toast.error(errorData.phone_number[0]);
            } else if (errorData && errorData.email) {
              toast.error(errorData.email);
            }

          }
        });

      } catch (error) {
        setLoading((s) => !s);
        if (error.message === 'Invalid phone number format.') {
          toast.error('Invalid phone number format.');
        } else if (error.message === 'An account with the given email already exists') {
          toast.error('An account with the given email already exists');
        } else {
          toast.error(error.message);
        }
        console.log('error signing up', error);
      }
    }
  }
  return (
    <>
      {/* <div style={{ position: 'absolute', bottom: '2%', right: '2%' }}>
        <Link to='/about' style={{ fontWeight: '600', textDecoration: 'none' }}>
          About Us
        </Link>
      </div> */}
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv py-lg-5 py-md-4 py-0'>
          <form className='LogIn_FormDiv' onSubmit={(e) => e.preventDefault()}>
            <div className='LogIn_HeadingDiv'>
              <h2>Hello,</h2>
              <p>let’s help find your lost item</p>
            </div>
            <div className='LogIn_InputDiv'>
              <h3 className='pb-4'>Sign Up</h3>

              <FormControl fullWidth className='mb-1'>
                <label for="Name" style={{ display: "inline-flex" }}>
                  Full Name <span className='text-danger'>*</span>
                </label>
                <input
                  className='LogIn_InputEmail'
                  required
                  value={formState.name}
                  onChange={(e) => {
                    setFormState((s) => ({ ...s, name: e.target.value }));
                  }}
                  type='text'
                  placeholder='Enter Full Name'
                />
              </FormControl>
              <FormControl fullWidth className='mb-1'>
                <label for="Email" style={{ display: "inline-flex" }}>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  className='LogIn_InputEmail'
                  required
                  value={formState.email}
                  onChange={(e) => {
                    setFormState((s) => ({ ...s, email: e.target.value }));
                  }}
                  type='email'
                  placeholder='Enter Email '
                />
              </FormControl>
              <FormControl fullWidth className='mb-1'>
                <label for="Phone" style={{ display: "inline-flex" }}>
                  Phone Number <span className='text-danger'>*</span>
                </label>
                <PhoneInput
                  international
                  className='LogIn_InputEmail'
                  placeholder='Enter Phone Number'
                  defaultCountry='US'
                  value={formState.phone && formState.phone.replace(/^(?!\+)/, '+')}
                  onChange={(phone) => {
                    setFormState((s) => ({ ...s, phone: phone }));
                  }}
                />
              </FormControl>
              <FormControl fullWidth className='mb-1'>
                <label for="Password" style={{ display: "inline-flex" }}>
                  Password <span className='text-danger'>*</span>
                </label>
                <div className='LogIn_InputPasswordDiv'>
                  <input
                    className='LogIn_InputPassword'
                    required
                    value={formState.password}
                    onChange={(e) => {
                      setFormState((f) => ({ ...f, password: e.target.value }));
                    }}
                    type={!showPassword ? 'password' : 'text'}
                    placeholder='Enter Password'
                  />
                  {/* <img
                  style={{ cursor: 'pointer' }}
                  src={HidePassword}
                  onClick={() => setShowPassword(!showPassword)}
                /> */}
                  <div>
                    {showPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
              </FormControl>
              {/* <input
                className='LogIn_InputEmail'
                value={formState.coupon}
                onChange={(e) => {
                  setFormState((s) => ({ ...s, coupon: e.target.value }));
                }}
                type='text'
                placeholder='Coupon Code'
              /> */}
            </div>
            {/* <div className='LogIn_ForgotPasswordDiv'>
              <p>Forgot password?</p>
            </div> */}

            {/* <div className='LogIn_SignUpDiv mt-2' style={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
              <input className="form-check-input" style={{ width: '2em' }} name="development" value={checked} onChange={(e) => setChecked(e.target.checked)} type="checkbox"></input>
              <p className='ml-1 text-align-left-important'>
                I have read, understood, and hereby accept the&nbsp;
                <a href='https://qrtag.it/terms-and-conditions/' target='_blank' style={{ color: '#0A3F74', fontWeight: '600' }}>
                  Terms and Conditions
                </a> and&nbsp;
                <a href='https://qrtag.it/privacy-policy/' target='_blank' style={{ color: '#0A3F74', fontWeight: '600' }}>
                  Privacy Policy
                </a>
              </p>
            </div> */}

            <div className='LogIn_BtnDiv' onClick={signUp}>
              <Link className='LogIn_Btn'>
                {!loading ? <div> Sign Up</div> : <PulseLoader size={15} color='#ffffff' />}
              </Link>
              {/* <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={60}
                aria-label='Loading Spinner'
                data-testid='loader'
              /> */}
            </div>
            <div className='LogIn_SignUpDiv'>
              <p>
                Already have an account?{' '}
                <Link to='/' style={{ color: '#0A3F74', fontWeight: '700' }}>
                  Sign In
                </Link>
              </p>
            </div>
            <div className='LogIn_DividerDiv'>
              <div className='LogIn_Divider' />
              <p>OR</p>
              <div className='LogIn_Divider' />
            </div>
            <div className='LogIn_SignInOptions'>
              <p>Sign up with</p>
            </div>
            <SocialLogin />
          </form>
        </div >
      </div >
    </>
  );
}

export default SignUp;
