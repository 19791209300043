import Axios from '../config/axios';
import { UpdateStoreRef } from '../StoreContext';

export const HandleTempUser = (
  postData,
  callback = ({ success, data }) => { }
) => {
  Axios.post(`/users/create-temp-user/`, postData)
    .then((response) => {
      // console.log('HandleTempUser res', response);

      callback({ success: true, data: response?.data });
      UpdateStoreRef.current.updateStore({
        user: { id: response.data.user_id },
      });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const UpdateProfile = (
  url,
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.patch(url, postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error });
    });
};

export const GetStates = (
  callback = ({ success, data }) => { }
) => {
  Axios.get(`/common/states/`)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const GetMyQRTags = (
  callback = ({ success, data }) => { }
) => {
  Axios.get(`/qrcode/qr-codes/`)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false });
    });
};

export const SendQRCode = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post('/qrcode/mail/', postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error });
    });
};

export const ApplyCouponCode = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post('/users/apply-coupon/', postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error.response });
    });
};

export const GetUserProfile = (
  callback = ({ success, data }) => {}
) => {
  Axios.get('/users/detail/')
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error });
    });
};

export const AddMyItem = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post('/looser/items/', postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error.response });
    });
};

export const RequestHonoraryAccess = (
  postData,
  callback = ({ success, data }) => {}
) => {
  Axios.post('/looser/access-request/', postData)
    .then((response) => {
      callback({ success: true, data: response?.data });
    })
    .catch((error) => {
      callback({ success: false, error: error.response });
    });
};