/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import { useEffect, useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthAcceptTerms } from '../../services/auth';

function AcceptTerms() {

  const { state } = useLocation();
  const [username] = useState(state?.username);
  const nav = useNavigate();

  let [loading, setLoading] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [termsContent, setTermsContent] = useState(null);
  const [isDynamic, setIsDynamic] = useState(false);

  useEffect(() => {
    if (!username) nav('/');
  }, []);

  useEffect(() => {
    async function fetchTerms() {
      try {
        const response = await fetch('https://qrtag.it/wp-json/wp/v2/pages/?slug=terms-and-conditions');
        const data = await response.json();
        if (data && data.length > 0) {
          setTermsContent(data[0].content.rendered);
          setIsDynamic(true)
        }
      } catch (error) {
        console.error('Error fetching terms:', error);
      }
    }

    fetchTerms();
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      const scrollableDiv = document.getElementById('terms-container');
      if (scrollableDiv) {
        const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
        if (scrollTop + clientHeight >= scrollHeight) {
          setIsButtonEnabled(true);
        } else {
          setIsButtonEnabled(false);
        }
      }
    };

    const scrollableDiv = document.getElementById('terms-container');
    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    window.scrollTo({ top: 0 })

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  async function handleSubmit() {
    setLoading((s) => !s);
    try {
      AuthAcceptTerms({ email: username }, (response) => {
        setLoading((s) => !s);
        if (response && response.success) {
          nav('/')
          toast.success('Terms and Conditions accepted successfully');
        } else {
          toast.error("Signup Failed. Please try again later!")
        }
      });
    } catch (error) {
      if (error.message === 'Confirmation code cannot be empty') {
        toast.error('Confirmation code cannot be empty.');
      } else {
        toast.error('Invalid verification code provided, please try again.');
      }
      console.log('error confirming sign up', error);
      setLoading((s) => !s);
    }
  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <div className='LogIn_FormDiv' style={{ width: '80%' }}>
            <div className='content-div ' id='terms-container' style={{ maxHeight: '550px', overflowY: 'auto' }}>
              {(isDynamic) ?

                <div className="terms-content mt-5" dangerouslySetInnerHTML={{ __html: termsContent }} />

                : <>
                  <div >
                    <h1 className='text-center mt-3 mb-5'>TERMS OF SERVICE</h1>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>1.Introduction</h2>
                      <p>Thank you for joining QRTag.it! These Terms of Service (the “Terms” or “Agreement”) cover your rights and obligations relating to your access and use of our QRTag.it mobile application (the “App”) and website (www.QRTag.it) (the “Website”), as well as any other related products and services offered through our Website (collectively, the “Service”). All references to “we”, “us”, “our”, or “QRTag.it” refer to South Pillar Capital LLC. All references to “you”, “your”, or “Users” refer to the person who uses or accesses the Service, including, without limitation, Owners and Participants (as those terms are defined below). In addition to these Terms, please review our Privacy Policy, which describes our practices related to collection and use of your information to provide the Service. These Terms apply to our Privacy Policy as well. By using the Service, you represent and agree that you have read, understand, and agree to accept and be bound by both these Terms and our Privacy Policy as binding agreements on behalf of yourself and on behalf of the entity you represent, if any. Further, by maintaining an Account, you agree that these Terms and our Privacy Policy apply to your past use, if any, of the Service prior to the Effective Date. </p>
                      <p>You hereby represent that you have the full right, power, and authority to enter into these Terms and to fully perform all of your obligations hereunder and that you are under no legal disability or contractual restriction that prevents you from entering into these Terms.</p>
                      <p>PLEASE READ THESE TERMS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND RESPONSIBILITIES, INCLUDING LIMITATION OF OUR LIABILITY AND BINDING ARBITRATION. IF YOU DO NOT ACCEPT THIS AGREEMENT IN ITS ENTIRETY, YOU MAY NOT ACCESS OR USE THE SERVICE.</p>
                      <p>Do not hesitate to contact us at dave@qrtag.it if you have any questions or want to discuss either of these important documents. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>2.Service</h2>
                      <h3 className='text-left my-4 text-uppercase'>2.1. Overview</h3>
                      <p>
                        QRTag.it is a communication platform that allows vehicle owners “Owners” to communicate securely and anonymously in real time with any third party who may interact with their vehicles “Participants”. QRTag.it was founded on the belief that so many issues surrounding the day-to-day use of vehicles can be avoided, or at least de-escalated, if people had the chance to communicate with one another. Although you will need to provide us with your information to register your Account (see below), Owners and Participants will remain anonymous to each other to help foster open communication, and will be able to send moderated text messages and images to each other.
                      </p>
                      <h3 className='text-left my-4 text-uppercase'>2.2 Account Registration</h3>
                      <p>
                        To access and use the App, you will need to register an Account. To register for an Account, you will need to provide us with your name, phone number and email address. In order to register as an Owner, we will need additional information about your vehicle, including the state where the vehicle is registered, as well as the make, model, year, color, vehicle identification number and license plate number of the vehicle – information that would be plainly visible if were parked on a community street. Please see our Privacy Policy for more information on our practices related to collection and use of your data. You agree to our use of all such information. </p>
                      <p> You agree to provide accurate, current, and complete information during the Account registration process and to update such information to keep it up to date. We reserve the right to suspend or terminate your Account according to these Terms if we determine that any information provided by you is inaccurate or incomplete. If we terminate or suspend your Account for any reason, you may be prohibited from registering and creating a new Account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. </p>
                      <p> If you become aware that an unauthorized third party is using your information to interact with the Service, you must promptly notify us of such use. You agree that we will not be liable for any loss or harm that you incur if someone else uses your information to interact with the Service, either with or without your knowledge, and that you will be solely responsible for such use, whether or not you authorized the use. You are responsible for safeguarding your Account information, including your password. You agree not to disclose your password to, or share your Account with, any third party. You are solely responsible for maintaining and protecting your Account. </p>
                      <p> Access to the App requires access to a smartphone or other connected device, and the manner in which you use the App may cause you to incur extra data, text messaging or other charges from your wireless carrier, which are your sole responsibility.
                      </p>
                      <h3 className='text-left my-4 text-uppercase'>2.3 Registering Your Vehicle</h3>
                      <p>To register as an Owner, you will need to register your vehicle with QRTag.it. Upon completing your Account registration as an Owner, you will be mailed a QR Code sticker, which you must place on the inside of your windshield and otherwise in strict adherence to any instructions QRTag.it may provide.
                      </p>
                      <h3 className='text-left my-4 text-uppercase'>2.4 Free and Paid Subscriptions </h3>
                      <p>Registration of an Account as a Participant is free of charge. Registration of an Account as an Owner, however, may require a paid subscription. Owners will have the opportunity to select the subscription of their choice upon creation of their Account. If you pay a subscription fee, you agree to the fees and payment and billing practices as made available to you at Account registration. QRTag.it may add new services for additional fees and charges, or amend fees and charges for existing services, at any time in its sole discretion. All fees and charges for your use of the Service are non-refundable, except as may be expressly stated on the Website.</p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>3. AGE POLICY</h2>
                      <p>
                        We do not intend that the Service be used by anyone under 13 years old. If we learn or have reason to suspect that an individual who has created an Account is under 13 years of age, we will promptly revoke such individual’s access to the Service and delete any personally identifiable information submitted by that individual. If you are registering as an Owner, you must be at least 18 years old.
                      </p>
                      <p>Further, you represent and warrant that your use of the Service does not and will not conflict with any pre-existing obligation in conflict or in any way inconsistent with the provisions of these Terms.</p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>4. PERMITTED USE OF THE SERVICE</h2>
                      <p>You may only use the Service for its intended purpose. You may not use the Service for any other purpose. Any non-permitted use of the Service, as determined in our sole discretion, shall be grounds for immediate termination of these Terms and immediate termination of your use of, and access to, the Service. </p>
                      <p>You are prohibited from violating or attempting to violate any security features of the Service, including, without limitation, taking any of the following actions: </p>
                      <p>accessing content or data not intended for you, or logging onto a server or account that you are not authorized to access; </p>
                      <p>attempting to probe, scan, or test the vulnerability of the Service, or any associated system or network, or to breach security or authentication measures without proper authorization; </p>
                      <p>interfering or attempting to interfere with service to any User, host, or network, including, without limitation, by means of submitting a virus to the Service, overloading, “flooding,” “spamming,” “mail bombing,” “crashing,” or undertaking similar activities; </p>
                      <p>forging any TCP/IP packet header or any part of the header information in any email or in any posting using the Service; or </p>
                      <p>copying, selling, distributing, publishing, downloading, or reproducing any aspect of the Service.
                      </p>
                      <p>Further, you agree not to take any of the following actions when using the Service: </p>
                      <p>modifying, making derivative works of, decompiling, reverse-engineering, disassembling, or otherwise converting any aspect of the Service; </p>
                      <p>licensing, selling, renting, leasing, transferring, assigning, distributing, hosting, or otherwise commercially exploiting the Service; </p>
                      <p>accessing the Service in order to build a similar or competitive service; </p>
                      <p>accessing (or attempting to access) any part of the Service by means other than through the interface that is provided by us; </p>
                      <p>removing, obscuring or altering any proprietary rights notices (including copyrights and trademark notices) which may be contained in or displayed in connection with the Service; </p>
                      <p>using the Service to transmit or send unsolicited commercial communications; or </p>
                      <p>using the Service except as permitted by these Terms. </p>
                    </div>

                    <div>
                      <h2 className='text-left my-4 text-uppercase'>5. YOUR CONDUCT AND CONTENT</h2>
                      <p>You agree not to transmit any inappropriate content on the Service (including via direct messages to other Users), including, but not limited to, libelous, defamatory, obscene, pornographic, extortive, abusive, or threatening content; content that advocates or encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; content that is misleading or not true; or advertise or otherwise solicits funds for goods or services except as authorized by QRTag.it. We may remove such content from our servers, and we may suspend or revoke your access to the Service, and we reserve the right to investigate, and seek applicable remedies for, violations of applicable law to the fullest extent of the law.</p>
                      <p>You agree to be respectful of other Users. Throughout such interactions, you will respect the privacy (including without limitation private, family and home life) and data protection rights of all Users with whom you communicate through the Service. By making use of the Service, you understand and agree that we have the right to monitor your access and use of the Service for the purpose of operating the Service, to ensure compliance with these Terms, and to comply with applicable law or other legal requirements. </p>
                      <p>If you come across a User or message that violates any of the above, please report it to us by emailing us at dave@qrtag.it. Although QRTag.it strives to encourage a respectful User experience, you understand and agree that your interactions with other Users are solely between you and such Users, and that QRTag.it is not responsible or liable for the conduct of any User on or off of the Service that we provide. You agree to use caution in all interactions with other Users, particularly if you decide to communicate off the Service, meet in person or exchange personal information. </p>
                      <p>We take abusive conduct very seriously, and use third-party content moderation services to help us identify such conduct and take action. You understand and agree that we may use such services to scan messages between you and other Users for this purpose. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>6. OWNERSHIP OF THE SERVICE</h2>
                      <p>The Service contains important and proprietary property owned by us, including software that constitutes our intellectual property and trade secrets. Nothing in these Terms shall be construed as a conveyance of any ownership right or title in or to our property. We only grant you a nonexclusive, non-transferable, non-sublicensable, and revocable license to use the Service for the purposes permitted by these Terms, and only for as long as you are permitted to access the Service. </p>
                      <p>Further, while using the Service, you may receive information disclosed by us that would, under the circumstances of such disclosure, appear to a reasonable person to be confidential or proprietary (“Confidential Information”). You may not use any of our Confidential Information for your own use or for any purpose other than to carry out discussions concerning, and the undertaking of, your use of and participation in the Service (the “Relationship”). You shall not disclose or permit disclosure of any Confidential Information to third parties. You shall take reasonable measures to protect the secrecy of and avoid disclosure or use of our Confidential Information in order to prevent it from falling into the public domain or the possession of persons other than those persons authorized under these Terms to have any such information. Such measures shall include the degree of care that you use to protect your own confidential information of a similar nature. You shall immediately notify us of any misuse, misappropriation or unauthorized disclosure of our Confidential Information that may come to your attention. </p>
                      <p>Lastly, you may choose to, or we may invite you to submit comments, suggestions, or ideas about the Service, including how to improve the Service (“Feedback”). By submitting any Feedback, you agree that your submissions are voluntary, gratuitous, unsolicited, and without restriction and will not place us under any fiduciary or other obligation. We may use, copy, modify, publish, or redistribute the submission and its contents for any purpose and in any way without any compensation to you. You also agree that we do not waive any rights to use similar or related ideas previously known to us, developed by our employees, or obtained from other sources. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>7. THIRD PARTY SITES AND SERVICES</h2>
                      <p>Our Service may be integrated with services provided by third parties as part of the functionality of the Service. You understand that we do not have control over third parties and that such third parties are not agents of QRTag.it. YOU ACKNOWLEDGE AND AGREE THAT WE MAKE NO REPRESENTATION OR WARRANTY ABOUT, DO NOT ENDORSE, AND WILL NOT BE LIABLE FOR ANY THIRD PARTY’S PRODUCTS OR SERVICES OR THE INFORMATION PROVIDED BY THIRD PARTIES, WHETHER THROUGH THE SERVICE OR OTHERWISE. Accordingly, we are not responsible for your use of any third-party product or service or any harm or losses arising from or relating to your use of any third-party products or services. You should contact the third party with any questions about their products and services. We hereby disclaim and you hereby discharge, waive and release us and our licensors and suppliers from any past, present, and future claims, liabilities, and damages, known or unknown, arising out of or relating to your interactions with such third parties and their products and services. </p>
                      <p>If you are a California resident, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR OR RELEASED PARTY.” YOU HEREBY WAIVE ANY SIMILAR PROVISION IN ANY OTHER JURISDICTION. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>8. UPDATES TO THE SERVICE</h2>
                      <p>We may, from time to time, develop patches, bug fixes, updates, upgrades and other modifications to improve the performance of the Service (“Updates”). These may be automatically installed without providing any additional notice or receiving any additional consent. If you do not want such Updates, your remedy is to terminate your Account and stop using the Service. If you do not terminate a previously created Account, you may receive Updates automatically. You acknowledge that you may be required to install Updates to use the Service. Your continued use of the Service is your agreement to these Terms with respect to the Service. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>9. INFORMATION WE MAKE AVAILABLE TO YOU</h2>
                      <p>As part of the Service, we may provide you with various information in furtherance of the Service. Our intention in doing so is to be helpful and to make the Service more useful to you. However, you agree that all information and suggestions that we provide to you through the Service is strictly for informational purposes and shall not be construed or relied upon in any way, and specifically shall not be construed or relied upon as professional advice of any kind. You agree that, to the fullest extent permitted by law, and as detailed below in the “Limitation of Liability” section of these Terms, we will not incur any liability at all whatsoever in the event that your reliance on any information provided by us results in harm or damage to you or your property. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>10. INFORMATION YOU PROVIDE TO US</h2>
                      <p>As a core aspect of the functionality of the Service, you may provide us with information about yourself, by giving us access to information collected by third parties, and by using the Service. By providing this information and content, you grant us the right to use the information and content for the purposes described in these Terms and our Privacy Policy. While we do not claim ownership over any such information and content that you provide, you agree that we have the right to use such information and content as specified in these Terms and our Privacy Policy. Further, by providing us with information and content through the Service, you represent and warrant that you own, or have the full legal authority to distribute, all information that you provide on the Service. </p>
                    </div>

                    <div>
                      <h2 className='text-left my-4 text-uppercase'>11. NOTICE AND PROCEDURE FOR MAKING U.S. CLAIMS OF COPYRIGHT OR TRADEMARK INFRINGEMENT</h2>
                      <p>As a core aspect of the functionality of the Service, you may provide us with information about yourself, by giving us access to information collected by third parties, and by using the Service. By providing this information and content, you grant us the right to use the information and content for the purposes described in these Terms and our Privacy Policy. While we do not claim ownership over any such information and content that you provide, you agree that we have the right to use such information and content as specified in these Terms and our Privacy Policy. Further, by providing us with information and content through the Service, you represent and warrant that you own, or have the full legal authority to distribute, all information that you provide on the Service. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>12. INFORMATION YOU PROVIDE TO US</h2>
                      <p>If you believe that your work has been copied in a way that constitutes copyright and/or trademark infringement, please provide a notice (a “DMCA Notice”) with the following information to QRTag.it’s Copyright Agent: </p>
                      <p>a. An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or trademark interest;</p>
                      <p>b. A detailed description of the copyright work or trademark that you claim has been infringed;</p>
                      <p>c. A description of precisely where the material that you claim is being infringed is located on the Service;</p>
                      <p>d. Your address, telephone number, and email address;</p>
                      <p>e. A statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or trademark owner, its agent or the law; and </p>
                      <p>f. A statement by you, made under penalty of perjury, that the above information in your Notice is accurate and that you are the copyright or trademark owner or authorized to act on the copyright or trademark owner's behalf.</p>
                      <p>QRTag.it’s Copyright and Trademark Agent for DMCA Notices is:</p>
                      <p>Attention: South Pillar Capital LLC </p>
                      <p>Email: dave@qrtag.it </p>
                      <p>We may give notice to our Users by means of a general notice on our Service, electronic mail to a User’s email address in our records, or by written communication sent by first-class mail to a User’s physical address in our records. If you receive such a notice, you may provide counter-notification in writing to the designated Copyright and Trademark Agent. To be effective, the counter-notification must be a written communication that includes the following: </p>
                      <p>a. Your physical or electronic signature;</p>
                      <p>b. Identification of the material that has been removed or to which access had been disabled, and the location at which the material appeared before it was removed or access to it was disabled;</p>
                      <p>c. A statement from you under the penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of mistake to misidentification of the material to be removed or disabled; and</p>
                      <p>d. Your name, physical address, and telephone number, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your physical address is located, or if your physical address is outside of the United States, for any judicial district in which QRTag.it may be found, and that you will accept service or process from the person who provided notification of alleged infringing material or an agent of such person. QRTag.it may terminate Accounts that have been the subject of five (5) separate DMCA notices. In the event a User’s materials are removed due to a DMCA Notice and then subsequently restored due to the filing of a counter-notification, QRTag.it will treat the underlying DMCA Notice as withdrawn. QRTag.it reserves the right to terminate Accounts that are the subject of fewer than five (5) DMCA Notices in appropriate circumstances - such as where the User has a history of violating or willfully disregarding these Terms.</p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>13. YOUR USE OF OTHERS’ INTELLECTUAL PROPERTY</h2>
                      <p>Although you may provide information and content to QRTag.it as part of your use of the Service, you agree to be respectful of others’ intellectual property rights. You may not upload, transmit, or otherwise distribute any information or content in violation of intellectual property laws or proprietary rights of any third parties. If you do not respect a third party’s intellectual property or proprietary rights, you are solely responsible for any violations of law. In addition, you are solely responsible for infringement of third-party rights caused by any information that is generated or submitted through your use of the Service. We take claims of intellectual property infringement seriously. As such, we reserve the right to suspend and/or revoke access to the Service for any User who is found to have infringed on the intellectual property rights of third parties, or us, or otherwise is found to have violated any intellectual property laws. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>14. TERMINATION OF YOUR USE OF THE SERVICE</h2>
                      <p>At any time, you may discontinue your use of the Service. To request deactivation of your Account, please email dave@qrtag.it. Please visit our Privacy Policy to understand how we treat your information when you discontinue your use of the Service. </p>
                      <p>We reserve the right to take action to limit or prevent your access to our Service, if we, in our sole discretion, deem that such action is necessary based on: your use of the Service in a way that would potentially expose us to liability; disruption of the Service by you to others; your violation of these Terms or our Privacy Policy (including, without limitation, your failure to pay any fees); your violation of any applicable laws, rules, regulations, and agreements that may apply to you; and your use of the Service in a way that could cause harm to any person. In addition, we may terminate the Service at any time and for any reason upon notice to you. </p>
                      <p>terminate the Service at any time and for any reason upon notice to you. After termination by either party, you shall no longer have access to, and shall cease all use of, the Service. In addition, all licenses granted to you by QRTag.it under this Agreement will end. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>15. ASSUMPTION OF RISK</h2>
                      <p>YOUR USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK. YOU AGREE THAT IF YOU CHOOSE TO USE THE SERVICE, YOU ARE DOING SO VOLUNTARILY AND YOU ASSUME ALL RISKS WITHOUT LIMITATION. </p>
                      <p>YOU ACKNOWLEDGE AND AGREE THAT THERE RISKS ASSOCIATED WITH USING THE SERVICE, INCLUDING, WITHOUT LIMITATION, RISKS OF HARM AND INJURY TO YOURSELF AND/OR YOUR PROPERTY (E.G. YOUR VEHICLE) IN CONNECTION WITH INTERACTIONS WITH OTHER USERS (WHETHER ONLINE OR OFFLINE). IT IS POSSIBLE THAT OTHER USERS MAY ATTEMPT TO PHYSICALLY HARM YOU OR YOUR PROPERTY, OR DEFRAUD YOU OR OBTAIN INFORMATION FROM YOU FOR FRAUDULENT PURPOSES. YOU UNDERSTAND AND AGREE THAT WE CANNOT CONFIRM THAT EACH USER IS WHO HE/SHE/THEY CLAIM TO BE, AND, EXCEPT AS EXPLICITLY LISTED HEREIN, QRTag.it DOES NOT INVESTIGATE ANY USER OR THEIR CRIMINAL BACKGROUND. YOU ACKNOWLEDGE THAT WE ARE UNDER NO OBLIGATION TO CONDUCT ANY SUCH INVESTIGATIONS. USERS ARE SOLELY RESPONSIBLE FOR TAKING ALL NECESSARY PRECAUTIONS WHEN INTERACTING WITH OTHER USERS, ESPECIALLY IF MEETING IN PERSON OFFLINE. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>16. DISCLAIMER OF WARRANTIES; “AS IS”</h2>
                      <p>WE ARE MAKING THE SERVICE AVAILABLE “AS IS” AND, TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SERVICE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT, OR ANY RESULTS THAT MAY BE OBTAINED FROM YOUR USE OF THE SERVICE. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SERVICE. WE DO NOT WARRANT THAT OPERATION OF THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. </p>
                      <p>QRTag.it is actively developing new features and products to improve the Service. As part of these efforts, we may add or remove features, start offering new services, or stop offering some services entirely (or just in some places or for some Users) if they no longer make sense from a business perspective or create risk for QRTag.it, our Users, or other third parties. While we try to avoid disruptions, we cannot guarantee that there will not be an outage or change to the Service, and your content may not be retrievable due to such outages or changes. We are not liable for any such outages or changes to the Service. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>17. LIMITATION OF LIABILITY</h2>
                      <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE INCLUDING, BUT NOT LIMITED TO, DAMAGES ARISING FROM OR RELATED TO ANY OF THE FOLLOWING: ANY CLAIM RELATED TO PERSONAL OR BODILY INJURY, OR DAMAGE TO YOUR PERSONAL PROPERTY, IN CONNECTION WITH THE USE OF THE SERVICE; ANY HARM OR DAMAGE CAUSED BY, OR OTHERWISE RELATED TO, YOUR RELIANCE ON INFORMATION PROVIDED THROUGH THE SERVICE; ANY LOSS OR UNAUTHORIZED DISCLOSURE OF DATA; ANY HARM OR DAMAGE CAUSED BY, OR OTHERWISE RELATED TO, YOUR USE OF OR INTERACTION WITH ANY SERVICES OFFERED THROUGH THE SERVICE; AND ANY VIOLATIONS OF TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE SHALL NOT BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SERVICE INCLUDING, BUT NOT LIMITED TO, DAMAGES ARISING FROM OR RELATED TO ANY OF THE FOLLOWING: ANY CLAIM RELATED TO PERSONAL OR BODILY INJURY, OR DAMAGE TO YOUR PERSONAL PROPERTY, IN CONNECTION WITH THE USE OF THE SERVICE; ANY HARM OR DAMAGE CAUSED BY, OR OTHERWISE RELATED TO, YOUR RELIANCE ON INFORMATION PROVIDED THROUGH THE SERVICE; ANY LOSS OR UNAUTHORIZED DISCLOSURE OF DATA; ANY HARM OR DAMAGE CAUSED BY, OR OTHERWISE RELATED TO, YOUR USE OF OR INTERACTION WITH ANY SERVICES OFFERED THROUGH THE SERVICE; AND ANY VIOLATIONS OF </p>
                      <p>IN NO EVENT WILL OUR AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS AND YOUR USE OF THE SERVICE EXCEED THE COST OF AN ANNUAL QRTag.it SUBSCRPTION. </p>
                      <p>THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN YOU AND US.</p>
                      <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CERTAIN TYPES OF DAMAGES OR CAUSES OF ACTION, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>18. INDEMNIFICATION</h2>
                      <p>In the event that any third party brings a claim against us related to your actions, content, information, or any other use of the Service by you, you agree to indemnify, defend, and hold us and our agents harmless from and against any and all third-party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) relating to such claim. We will notify you promptly of any such claim and will provide you with reasonable assistance, at your expense, in defending any such claim, provided, however, that our failure to so notify you shall not relieve you of your indemnity obligations, but instead shall reduce those obligations by the amount of damages or increased costs and expenses attributable to our failure to give notice. We reserve the right to approve counsel retained by you, to take control of the defense (at our expense) of any claim for which indemnity is required, and to participate in the defense of any claim (at our expense) for which indemnity is required. You may not settle any claim without our prior consent. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>19. LOCATION INFORMATION</h2>
                      <p>When you use the Service, we may collect and process information about your actual location, like GPS signals sent by a mobile device. We use this location information in order to protect against actual or potential fraud. For example, we may collect location information to verify that a Participant is where the Owner’s vehicle was parked to avoid potential scams involving the theft or copying of a QR Code sticker. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>20. DISPUTE RESOLUTION</h2>
                      <p>You agree to resolve any dispute, claim, or controversy with QRTag.it arising out of or relating to your use in any way of the Service in the following manner. First, we both agree to attempt in good faith to resolve the dispute informally by contacting each other by email (you can email us at dave@qrtag.it). Second, if the dispute is not resolved through informal resolution, we both agree to attempt in good faith to resolve the dispute through mediation administered by JAMS Comprehensive Arbitration Rules & Procedures (“JAMS”), which shall take place in Philadelphia, Pennsylvania, and the costs of which shall be divided equally between you and QRTag.it. Third, if the dispute is not resolved through informal resolution and mediation, we both agree to participate in binding arbitration administered by JAMS, which shall take place in Philadelphia, Pennsylvania. </p>
                      <p>Either you or we may bring a lawsuit solely for injunctive relief without first engaging in the dispute resolution process described above. </p>
                      <p>We both agree that, in the event of arbitration, or in the event of a lawsuit as permitted by this section or otherwise, the prevailing party shall be entitled to costs and fees (including reasonable attorneys’ fees). Arbitration pursuant to this section shall be confidential, and neither you, nor QRTag.it, nor the arbitrator may disclose the existence, content or results of any arbitration, except as may be required by law or for purposes of enforcement or appeal of the arbitration award. Judgment on any arbitration award may be entered in any court having proper jurisdiction. There shall be no right or authority for any claims subject to this arbitration clause to be arbitrated on a class action or consolidated basis or on bases involving claims brought in a purported representative capacity on behalf of the general public (including, but not limited to, as a private attorney general). If any portion of this arbitration clause is determined by a court to be inapplicable or invalid, then the remainder shall still be given full force and effect. YOU AND QRTag.it EACH HEREBY IRREVOCABLY WAIVE ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED HEREBY. Nothing contained in this section shall limit our ability to take action related to your access to the Service as provided in these Terms. </p>
                      <p>If there is a dispute between Users of the Service, or between Users and any third party, you understand and agree that QRTag.it is under no obligation to become involved. In the event that you have a dispute with one or more other Users, you hereby release QRTag.it, its officers, employees, agents and successors in rights from claims, demands and damages (actual and consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to such disputes and/or the Service. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>21. GOVERNING LAW, VENUE, & PERSONAL JURISDICTION</h2>
                      <p>These Terms shall be governed by, and construed and interpreted in accordance with the laws of the State of Delaware to the exclusion of its conflict of laws provisions. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>22. FORCE MAJEURE</h2>
                      <p>Except for the payment of any fees, neither you nor QRTag.it will be responsible for any failure to perform or delay attributable in whole or in part to any cause beyond its reasonable control including, but not limited to, natural disasters (fire, storm, floods, earthquakes, etc.), a pandemic, acts of terrorism, civil disturbances, disruption of telecommunications, disruption of power or other essential services, interruption or termination of any third party services, labor disturbances, vandalism, cable cut, computer viruses or other similar occurrences, or any malicious or unlawful acts of any third party. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>23. ENTIRE AGREEMENT </h2>
                      <p>These Terms and the Privacy Policy set forth the entire agreement and understanding between you and QRTag.it relating to the subject matter hereof, and supersedes all prior or contemporaneous agreements, proposals, negotiations, conversations, discussions and understandings, written or oral, with respect to such subject matter and all past dealing or industry custom. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>24. SEVERABILITY WAIVER </h2>
                      <p>If, for whatever reason, any term or condition in these Terms is found unenforceable, all other terms and conditions will remain unaffected and in full force and effect. The failure to enforce any provision of these Terms is not a waiver of our right to do so later, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>25. CHANGES TO THESE TERMS  </h2>
                      <p>QRTag.it reserves the right to change these Terms from time to time, with or without notice to you. If you continue to use the Service, you consent to the new Terms. Any changes to these Terms will become effective on the “Effective Date” indicated above. If you continue to use the Service after the Effective Date, you consent to the new Terms. QRTag.it will always have the latest Terms posted on the Service. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>26. CONTACT US  </h2>
                      <p>If you have any questions about these Terms or our Service, please feel free to contact us by email at dave@qrtag.it. </p>
                    </div>
                    <div>
                      <h2 className='text-left my-4 text-uppercase'>27. HEADINGS USED IN THESE TERMS </h2>
                      <p>The section headings contained in these Terms are for reference purposes only and shall not affect the meaning or interpretation of these Terms in any way. </p>
                    </div>

                  </div>
                </>
              }
            </div>

            <div className='LogIn_SignUpDiv mt-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <p className='mb-0'>
                I Agree to Terms & Conditions
              </p>
            </div>
            <div className='LogIn_BtnDiv'>
              <button onClick={handleSubmit} className='LogIn_Btn'
                style={{
                  backgroundColor: !isButtonEnabled ? '#ccc' : '#0a3f74',
                  cursor: !isButtonEnabled ? 'not-allowed' : 'pointer',
                  opacity: !isButtonEnabled ? 0.6 : 1,
                  border: 'none',
                  color: '#fff',
                  padding: '10px 20px',
                  borderRadius: !isButtonEnabled ? '5px' : '25px',
                  transition: 'background-color 0.3s',
                }}
                disabled={!isButtonEnabled}
              >
                {!loading ? <div>AGREE</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptTerms;
