/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import '../css/App.css';
import Navbar from '../common/Navbar';
import QRTag from '../../images/about-QRTag.svg';
import DownloadLink from '../common/DownloadLink';
import BasicModal from '../common/BasicModal';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import TopNavbar from '../common/TopNavContent';
import { Store } from '../../StoreContext';

function About() {
  const location = useLocation();
  const { user, loggedIn } = Store();
  useEffect(() => {
    mixpanel.track('About Page', {
      pageName: "About Us"
    });
  }, [])

  return (
    <>
      <Navbar />
      {loggedIn &&
        <TopNavbar />
      }
      {location?.state?.error && <BasicModal />}
      <div className='About_Container container my-5'>
        <h3>About Us</h3>
        <div className='About_ImageDiv'>
          <img src={QRTag} />
        </div>
        <div className='About_TextDiv d-flex flex-column'>
          <div className='About_main'>
            <h1>About the QRTag.it App</h1>
            <h2 style={{ fontSize: '1.8rem' }}>
              Tag and keep track of all your valuable stuff with the QRTag.it app. If you misplace one of your items, mark it as lost and your tag will become active, allowing you to know the instant your tag has been found and where it was scanned.
            </h2>
            <p>Create an account for a free QRTag and Download the App today!</p>
          </div>
          <div className='About_details d-flex flex-column'>
            <div>
              <div className='About_detail_item'>
                <h2>Found An Item?</h2>
                <p>Finders scan the QRTag with their phone’s normal camera, no app necessary! They can easily chat with you through our website to get the item safely back into your slippery hands. You can even advertise a reward for the items return to sweeten the deal for the finder and incentivise them to get it back to you quickly</p>
              </div>
              <div className='About_detail_item'>
                <h2>Receive Notifications</h2>
                <p>Once you mark an item as lost you will be notified whenever the QRCode is scanned. Tapping the notification will take you to the app and allow you to easily communicate with the finder of your item. You can also see where the QRTag was scanned making it easier navigate back to your item.</p>
              </div>
            </div>
            <div>
              <div className='About_detail_item'>
                <h2>Chat Feature</h2>
                <p>Lost items that have been scanned will allow you to communicate with the finder of the item. You’re in control of who can communicate with you through the app. Finders can no longer communicate with you once your item is marked as returned.</p>
              </div>
              <div className='About_detail_item'>
                <h2>Provide Rewards</h2>
                <p>You can provide a reward for finders willing to return your lost items. WIth a couple of taps you can add your promised reward and your finder will be able to claim that reward through our website without sharing any personal information.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DownloadLink />
    </>
  );
}

export default About;
