import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button, FormControl, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { AddMyItem } from '../../services/user';
import { Add } from '@mui/icons-material'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -38%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
    overflow: 'auto'
};


export default function BasicModal({ open, setOpen, itemId, getList, itemTypes }) {

    const [loading, setLoading] = React.useState(false);
    const [photos, setPhotos] = React.useState(['', '', '']);
    const fileInputRefs = [React.useRef(null), React.useRef(null), React.useRef(null)];
    const [data, setData] = React.useState({
        name: '',
        manufacturer: '',
        serial: '',
        estimated: '',
        type: '',
        description: '',
        photos: []
    })

    const handleImageChange = (e, index) => {
        e.stopPropagation();
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const newPhotos = [...photos];
                newPhotos[index] = reader.result;
                setPhotos(newPhotos);

                setData((prevData) => ({
                    ...prevData,
                    photos: newPhotos
                }));
            };
        }
    };

    const handleClick = (index) => {
        if (fileInputRefs[index].current) {
            fileInputRefs[index].current.click();
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const handleRemoveImage = (index) => {
        const newPhotos = [...photos];
        newPhotos[index] = '';
        setPhotos(newPhotos);

        setData((prevData) => ({
            ...prevData,
            photos: newPhotos
        }));
    };

    async function submitItem() {

        if (data.name === '') {
            toast.error('Item name cannot be empty');
        } else if (data.manufacturer === '') {
            toast.error('Item manufacturer cannot be empty');
        } else if (data.serial === '') {
            toast.error('Item serial number cannot be empty');
        } else if (data.estimated === '' || isNaN(data.estimated)) {
            toast.error('Estimated value must be a number and cannot be empty');
        } else if (data.description === '') {
            toast.error('Item description cannot be empty');
        } else if (data.photos && data.photos.length < 3) {
            toast.error('You need to upload 3 images');
        } else {
            if (loading) {
                return;
            }
            const estimatedValue = parseFloat(data.estimated).toFixed(2);

            const params = {
                name: data?.name,
                manufacturer: data?.manufacturer,
                serial_no: data?.serial,
                item_type: data?.type,
                estimated_value: estimatedValue,
                description: data?.description
            }

            if (itemId) {
                params.item_id = itemId
            }

            const images = []
            if (data.photos && data.photos.length > 0) {
                for (let i = 0; i < data.photos.length; i++) {
                    if (data.photos[i] && data.photos[i] !== "") {
                        images.push({ photo: data.photos[i] })
                    }
                }
            }

            params.photos = images

            if (images && images.length < 3) {
                toast.error('You need to upload 3 images');
                return false
            }
            
            setLoading(true);
            try {
                AddMyItem(params, (response) => {
                    setLoading(false);
                    if (response && response.success) {
                        setOpen(false)
                        toast.success('Item added successfully!');
                        getList()
                    } else {
                        if (response && response.error && response.error.data && response.error.data.detail) {
                            toast.error(`${response && response.error && response.error.data.detail}`)
                        } else {
                            toast.error(`Failed to add item!`)
                        }
                    }
                });
            } catch (error) {
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                disableEscapeKeyDown
                style={{ overflow: 'auto' }}
            >
                <Box sx={style}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: '-5%',
                                marginTop: '-5%',
                                border: '2px solid #000',
                                borderRadius: '50%',
                                padding: '3px',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </div>

                    </div>
                    <div
                        className='text-center'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>

                        <Typography sx={{ marginTop: '5%' }} id='modal-modal-title' style={{ fontWeight: 'bold' }} component='h6' variant='h6'>
                            Add Item
                        </Typography>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label for="name" style={{ display: "inline-flex" }}>
                                    Item Name <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Enter Item Name'
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                    value={data.name}
                                    onChange={(e) => {
                                        setData((s) => ({ ...s, name: e.target.value }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label for="manufacturer" style={{ display: "inline-flex" }}>
                                    Item Manufacturer <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="text"
                                    id="manufacturer"
                                    name="manufacturer"
                                    placeholder='Enter Item Manufacturer'
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                    value={data.manufacturer}
                                    onChange={(e) => {
                                        setData((s) => ({ ...s, manufacturer: e.target.value }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label for="serial" style={{ display: "inline-flex" }}>
                                    Item Serial Number <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="text"
                                    id="serial"
                                    name="serial"
                                    placeholder='Enter Serial Number'
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                    value={data.serial}
                                    onChange={(e) => {
                                        setData((s) => ({ ...s, serial: e.target.value }));
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label for="estimated" style={{ display: "inline-flex" }}>
                                    Estimated Value <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail mb-2'
                                    type="text"
                                    id="estimated"
                                    name="estimated"
                                    placeholder='Enter Estimated Value'
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                    value={data.estimated}
                                    onChange={(e) => {
                                        let value = e.target.value;

                                        if (/^\d*\.?\d{0,2}$/.test(value)) {
                                            setData((s) => ({ ...s, estimated: value }));
                                        }
                                    }}
                                />
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }} className='select-card'>
                            <FormControl fullWidth className='mb-1'>
                                <label for="type" style={{ display: "inline-flex" }}>
                                    Item Type
                                </label>
                                <select 
                                    className='LogIn_InputEmail mb-2'
                                    value={data?.type} 
                                    onChange={(e) => {
                                        setData((s) => ({ ...s, type: e.target.value }));
                                    }}
                                    name="type"
                                    style={{
                                        width: '100%',
                                        height: '50px',
                                        padding: '10px'
                                    }}
                                >
                                    <option value=''>Select Type</option>
                                    {itemTypes && itemTypes.map((tp) => (
                                        <><option value={tp.id}>{tp.name}</option></>
                                    ))}
                                </select>
                            </FormControl>
                        </div>
                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label for="description" style={{ display: "inline-flex" }}>
                                    Description <span className='text-danger'>*</span>
                                </label>
                                <textarea
                                    className='LogIn_InputEmail' id="description" name="description" rows="3" cols="29" placeholder='Enter Description' style={{ width: '100%', padding: '3% 3%' }}
                                    value={data.description}
                                    onChange={(e) => {
                                        setData((s) => ({ ...s, description: e.target.value }));
                                    }}
                                />
                            </FormControl>
                        </div>

                        <div style={{ width: '100%' }}>
                            <FormControl fullWidth className='mb-1'>
                                <label htmlFor="image-upload" className='mb-2' style={{ display: "inline-flex" }}>
                                    Upload Item Images <span className='text-danger'>*</span>
                                </label>
                                <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    {photos.map((photo, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                width: 70,
                                                height: 70,
                                                borderRadius: '10%',
                                                backgroundColor: '#f5f5f5',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                position: 'relative',
                                                marginRight: '10px',
                                                marginTop: '10px',
                                                border: '1px solid rgb(223, 223, 223)'
                                            }}
                                            onClick={() => handleClick(index)}
                                        >
                                            {photo ? (
                                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                                    <img src={photo} alt={`Selected ${index}`} style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '-8px',
                                                            right: '-8px',
                                                            border: '2px solid #ff0000',
                                                            borderRadius: '50%',
                                                            padding: '3px',
                                                            backgroundColor: '#ff0000',
                                                            cursor: 'pointer',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveImage(index);
                                                        }}
                                                    >
                                                        <CloseIcon style={{ width: '13px', height: '13px', color: 'white' }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <Add style={{ width: '50%', height: '50%' }} />
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleImageChange(e, index)}
                                                style={{ display: 'none' }}
                                                ref={fileInputRefs[index]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </FormControl>
                        </div>

                        <Button
                            variant="outlined"
                            sx={{
                                mt: 3,
                                display: 'block',
                                width: '100%',
                                height: '50px',
                                mx: 'auto',
                                borderColor: '#0a3f74',
                                borderRadius: '30px',
                                backgroundColor: '#0a3f74',
                                color: '#fff',
                                '&:hover': {
                                    borderColor: '#0a3f74',
                                    color: '#fff',
                                    backgroundColor: '#0a3f74',
                                },
                            }}
                            onClick={submitItem}
                        >
                            {!loading ? (
                                <div>Submit</div>
                            ) : (
                                <PulseLoader size={15} color='#ffffff' />
                            )}
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}