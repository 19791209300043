import * as React from 'react';
import Navbar from '../common/Navbar';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Container, Button, Grid, FormControl, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { GetStates } from '../../services/user';
import { Store as ContextStore, UpdateStore } from '../../StoreContext';
import { UpdateProfile } from '../../services/user';
import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumber, validatePhoneNumberLength, getCountryCallingCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';
import TopNavbar from '../common/TopNavContent';

const formstyle = {
    width: '100%',
    height: '50px',
    padding: '10px',
}

const EditProfile = () => {

    const { loggedIn, user, channels, accessToken } = ContextStore();
    const updateStore = UpdateStore();

    const [selectedImage, setSelectedImage] = React.useState(null);
    const [profileImage, setProfileImage] = React.useState(null);
    const fileInputRef = React.useRef(null);
    let [loading, setLoading] = React.useState(false);
    let [states, setStates] = React.useState([]);
    let [email, setEmail] = React.useState(null);

    const [formState, setFormState] = React.useState({
        name: null,
        email: null,
        phone_number: null,
        state: null,
        city: null,
        address: null,
        postal_code: null
    });

    const getStateList = () => {
        try {
            GetStates((response) => {
                if (response && response.success) {
                    setStates(response?.data)
                } else {
                    setStates([])
                }
            });
        } catch (error) {
            console.log(false);
        }
    }

    React.useEffect(() => {
        getStateList()

        if (user) {
            setFormState({
                name: user?.name || '',
                phone_number: parsePhoneNumberFromString(user?.phone_number, user?.country_code)?.formatInternational() || '',
                state: user?.looser?.state || '',
                city: user?.looser?.city || '',
                address: user?.looser?.address,
                postal_code: user?.looser?.postal_code
            });
            setEmail(user?.email)
            setProfileImage(user?.looser?.profile_picture)
        }
    }, [])

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);  // Convert image to Base64
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    async function submitForm() {
        if (formState.name === '') {
            toast.error('Name cannot be empty');
        } else if (formState.phone_number === '' || typeof formState.phone_number === 'undefined') {
            toast.error('Phone number cannot be empty');
        } else if (formState.state === '') {
            toast.error('State cannot be empty');
        } else if (formState.city === '') {
            toast.error('City cannot be empty');
        } else {
            if (loading) {
                return;
            }
           
            try {
                const params = {
                    'name': formState?.name,
                    'phone_number': formState?.phone_number.replace(/\+/g, ''),
                    'state': formState?.state,
                    'city': formState?.city,
                    'address': formState?.address,
                    'postal_code': formState?.postal_code
                }

                try {
                    const phone = (formState.phone_number.startsWith('+') ? formState.phone_number : `+${formState.phone_number}`)
                    if (validatePhoneNumberLength(phone) === 'TOO_SHORT') {
                        toast.error('Please enter valid phone number');
                        return false
                    }
                    const parsedNumber = parsePhoneNumber(phone);
                    if (parsedNumber) {
                        params.phone_number = parsedNumber?.nationalNumber
                        params.country_code = parsedNumber?.country || 'US'
                 
                        if (parsedNumber?.nationalNumber === "") {
                            toast.error('Please enter valid phone number');
                            return false
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
                
                if (selectedImage) {
                    params.profile_picture = selectedImage
                }
              
                setLoading(true);
                UpdateProfile('/looser/me/', params, (response) => {
                    setLoading(false);
                    if (response && response.success) {
                        toast.success('Profile updated successfully!');

                        const temp = { ...user }
                        temp['name'] = response?.data.name;
                        temp['phone_number'] = response?.data.phone_number
                        temp['country_code'] = response?.data.country_code
                        temp['looser']['state'] = response?.data.state
                        temp['looser']['city'] = response?.data.city
                        temp['looser']['postal_code'] = response?.data.postal_code
                        temp['looser']['address'] = response?.data.address
                        temp['looser']['profile_picture'] = response?.data.profile_picture

                        localStorage.setItem('user', JSON.stringify(temp));
                        updateStore({ user: temp });
                        localStorage.setItem('userData', JSON.stringify(temp));

                    } else {
                        if (response && response.error && response.error.data && response.error.data.detail) {
                            toast.error(`${response && response.error && response.error.data.detail}`)
                        } else {
                            toast.error(`Failed to update profile!`)
                        }
                    }
                });
            } catch (error) {
                console.log(error)
                setLoading(false);
            }
        }
    }

    return (
        <div>
            <Navbar />
            <TopNavbar />
            <h1 className='Items_heading text-center pt-5 pb-lg-4 pb-md-0 pb-0 '>Edit Profile</h1>
            <Container maxWidth="md"
                sx={{ py: 5, padding: { xs: '10%', sm: '5%', md: 0, lg: 0 } }}>
                <div
                    className='text-center'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>

                    <div >
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                        <div
                            style={{
                                width: 130,
                                height: 130,
                                borderRadius: '50%',
                                backgroundColor: '#f5f5f5',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                position: 'relative',
                            }}
                            onClick={handleClick}
                        >
                            {selectedImage ? (
                                <img
                                    src={selectedImage}
                                    alt="Selected Image"
                                    style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                                />
                            ) : profileImage ? (
                                <img
                                    src={profileImage}
                                    alt="Selected Image"
                                    style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                                />
                            ) : (
                                <div

                                >
                                    <AccountCircleOutlinedIcon
                                        style={{
                                            width: '60%', height: '60%', color: '#0a3f74'
                                        }}
                                    />
                                </div>
                            )}
                            <span style={{
                                color: '#fff',
                                backgroundColor: '#0a3f74',
                                borderRadius: '50%',
                                display: 'inline-flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '30px',
                                height: '30px',
                                position: 'absolute',
                                bottom: '10px',
                                right: '0px',
                            }}>
                                +
                            </span>
                        </div>

                    </div>

                    <div className='mt-2 pb-4'>
                        <p>Add a profile image</p>
                    </div>

                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ width: '100%' }}>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="Name" style={{ display: "inline-flex" }}>
                                    Name  <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail '
                                    type="text"
                                    id="name"
                                    name="name"
                                    placeholder='Name'
                                    rows="4"
                                    style={formstyle}
                                    value={formState.name}
                                    onChange={(e) => { setFormState((s) => ({ ...s, name: e.target.value })) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="Email" style={{ display: "inline-flex" }}>
                                    Email <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail '
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder='Email'
                                    style={formstyle}
                                    value={email}
                                    readOnly
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ width: '100%' }}>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="Phone" style={{ display: "inline-flex" }}>
                                    Phone  <span className='text-danger'>*</span>
                                </label>
                                <PhoneInput
                                    international
                                    className='LogIn_InputEmail'
                                    placeholder='Enter Phone Number'
                                    defaultCountry='US'
                                    value={formState.phone_number && formState.phone_number.replace(/^(?!\+)/, '+')}
                                    onChange={(phone) => {
                                        setFormState((s) => ({ ...s, phone_number: phone }));
                                    }}
                                />
                                {/* <input
                                    className='LogIn_InputEmail'
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    placeholder='Phone'
                                    pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    style={formstyle}
                                    value={formState.phone_number}
                                    onChange={(e) => { setFormState((s) => ({ ...s, phone_number: e.target.value })) }}
                                /> */}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="State" style={{ display: "inline-flex" }}>
                                    State  <span className='text-danger'>*</span>
                                </label>
                                <select
                                    className='LogIn_InputEmail '
                                    id="State"
                                    name="State"
                                    placeholder='State'
                                    style={formstyle}
                                    value={formState.state}
                                    onChange={(e) => { setFormState((s) => ({ ...s, state: e.target.value })) }}
                                >
                                    <option value="">State</option>
                                    {(states.map((item) => (
                                        <><option value={item[0]}>{item[1]}</option></>
                                    )))}
                                </select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ width: '100%' }}>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="City" style={{ display: "inline-flex" }}>
                                    City  <span className='text-danger'>*</span>
                                </label>
                                <input
                                    className='LogIn_InputEmail'
                                    type="text"
                                    id="city"
                                    name="city"
                                    placeholder='City'
                                    style={formstyle}
                                    value={formState.city}
                                    onChange={(e) => { setFormState((s) => ({ ...s, city: e.target.value })) }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="postalCode" style={{ display: "inline-flex" }}>
                                    Postal Code
                                </label>
                                <input
                                    className='LogIn_InputEmail '
                                    type="text"
                                    id="postalCode"
                                    name="postalCode"
                                    placeholder='Postal Code'
                                    style={formstyle}
                                    value={formState.postal_code}
                                    onChange={(e) => { setFormState((s) => ({ ...s, postal_code: e.target.value })) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={{ xs: 0, sm: 2 }} sx={{ width: '100%' }}>
                        <Grid item xs={12} sm={12} className='mb-2'>
                            <FormControl fullWidth>
                                <label for="address" style={{ display: "inline-flex" }}>
                                    Street Address
                                </label>
                                <textarea
                                    className='LogIn_InputEmail' id="address" name="address" rows="4" cols="35" placeholder='Street Address' style={{ padding: '3% 3%', margin: '1% 0' }}
                                    value={formState.address}
                                    onChange={(e) => { setFormState((s) => ({ ...s, address: e.target.value })) }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Button
                        variant="outlined"
                        sx={{
                            my: 5,
                            display: 'block',
                            width: { xs: '100%', sm: '50%', md: '50%', lg: '50%' },
                            height: '50px',
                            mx: 'auto',
                            borderColor: '#0a3f74',
                            borderRadius: '30px',
                            backgroundColor: '#0a3f74',
                            color: '#fff',
                            // lineHeight: '1.25',
                            // whiteSpace: 'nowrap',
                            '&:hover': {
                                borderColor: '#0a3f74',
                                color: '#fff',
                                backgroundColor: '#0a3f74',
                            },
                        }}
                        onClick={submitForm}
                    >
                        {!loading ? (
                            <div >Update Profile</div>
                        ) : (
                            <PulseLoader size={15} color='#ffffff' />
                        )}
                    </Button>
                </div>
            </Container >
        </div >
    )
}
export default EditProfile