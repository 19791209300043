
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Navbar from '../common/Navbar'
import ItemBox from '../ItemBox'
import Axios from '../../config/axios'
import mixpanel from 'mixpanel-browser'
import ApplyCouponModal from '../common/ApplyCouponModal'
import AddItemModal from '../common/AddItemModal'
import UnlockFeatureModal from '../common/UnlockFeatureModal'
import QRScanModal from '../common/QRScanModal'
import { Store } from '../../StoreContext'
import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material'
import { BeatLoader } from 'react-spinners'
import TopNavbar from '../common/TopNavContent'
import FeedbackModal from '../common/FeedbackModal';

const MyStuff = () => {

  const { user, loggedIn, messages } = Store();
  const [allData, setAllData] = useState([])
  const nav = useNavigate()
  const { state } = useLocation();
  const [applycoupon, setApplyCoupon] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [featureModal, setFeatureModal] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [loading, setLoading] = React.useState();
  const [feedback, setFeedback] = React.useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isAppGuide, setIsAppGuide] = React.useState(state?.appGuide || false);
  const [itemTypes, setItemTypes] = useState([]);

  const handleMyStuffData = async () => {
    setLoading(true)
    const { data } = await Axios.get('/looser/items/')
    setLoading(false)
    if (data) {
      setAllData(data)
    }
  }

  const handleItemTypes = () => {
    Axios.get(`/common/item-type/`)
      .then((res) => {
        setItemTypes(res?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleMyStuffData()
    handleItemTypes()
    mixpanel.track('My Stuff Page', {
      pageName: "My Stuff"
    });
  }, [])

  const handleItemClick = (id) => {
    nav(`/mystuff/${id}`)
  }

  const getCookie = (name) => {
    let cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      let [key, value] = cookie.trim().split('=');
      if (key === name) return true;
    }
    return false;
  }

  useEffect(() => {
    if (user && user.is_tutorial_complete === true && user.is_flyer_activated === false) {
      if (isMobile) {
        if (isAppGuide === false) {
          setApplyCoupon(true);
        }
      } else {
        setApplyCoupon(true);
      }
    }
  }, [user]);

  const addItem = () => {
    if (user && user.subscription_plan === null && allData.length >= 1) {
      setFeatureModal(true)
      return;
    }
    setOpenModal(true)
  }

  useEffect(() => {
    if (itemId) {
      setAddModal(true)
    }
  }, [itemId])

  return (
    <>
      <Navbar />
      <TopNavbar />

      <div className='container my-5'>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">

          <Grid item xs={6} sm={6} display="flex" justifyContent={{ xs: 'flex-start', sm: 'center', lg: 'flex-start' }}>
            <h3 style={{ fontWeight: '600' }}>My Items</h3>
          </Grid>


          <Grid item xs={6} sm={6} display="flex" alignItems="center" justifyContent={{ xs: 'flex-end', sm: 'center', lg: 'flex-end' }} >
            <Button
              variant="outlined"
              sx={{
                height: '50px',
                borderColor: '#0a3f74',
                borderRadius: '25px',
                backgroundColor: '#0a3f74',
                color: '#fff',
                '&:hover': {
                  borderColor: '#0a3f74',
                  color: '#fff',
                  backgroundColor: '#0a3f74',
                },
              }}
              className="add-item-button"
              onClick={addItem}
            >
              <Add /> Add Item
            </Button>
          </Grid>
        </Grid>

        <div className=' d-flex flex-md-row flex-column justify-content-center align-items-center mt-4 mt-md-0' style={{ minHeight: '60vh' }}>
          {(loading) ? <>
            <div className='my-4'><BeatLoader /></div>
          </> : <>
            {(allData && allData.length > 0) ? <>

              {allData.map((item) => {
                return <ItemBox src={item?.thumbnail} status={item?.status} name={item?.name} key={item?.id} onClick={() => handleItemClick(item?.id)} />
              })}

            </> : <>
              <p className='text-muted text-center'>To add an item, simply click the "Add" button located above.</p>
            </>}
          </>}
        </div>
      </div>


      {applycoupon && (
        <ApplyCouponModal
          open={applycoupon}
          setOpen={setApplyCoupon}
        />
      )}

      {addModal && (
        <AddItemModal
          open={addModal}
          setOpen={setAddModal}
          itemId={itemId}
          getList={handleMyStuffData}
          itemTypes={itemTypes}
        />
      )}

      {featureModal && (
        <UnlockFeatureModal
          open={featureModal}
          setOpen={setFeatureModal}
        />
      )}

      {openModal && (
        <QRScanModal className="scanner"
          setItemId={setItemId}
          open={openModal}
          setOpen={setOpenModal}
        />
      )}

      {feedback && (
        <FeedbackModal className="feedback"
          open={feedback}
          setOpen={setFeedback}
        />
      )}


    </>
  )
}

export default MyStuff