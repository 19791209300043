/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { toast } from 'react-toastify';

import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthService } from '../../services/auth';
import { FormControl } from '@mui/material';

function ConfirmPassword() {
  const nav = useNavigate();
  const { state } = useLocation();
  // const username = state?.username;
  const [username] = useState(state?.username);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  let [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    password: '',
    confirmpassword: '',
  });

  async function confirmPassword() {
    if (formState.code === '') {
      toast.error('Confirmation code cannot be empty');
    } else if (formState.password === '') {
      toast.error('Password cannot be empty');
    } else if (formState.password.length < '8') {
      toast.error('Password must be at least 8 characters');
    } else if (formState.confirmpassword === '') {
      toast.error('Confirm password cannot be empty');
    } else if (formState.confirmpassword !== formState.password) {
      toast.error('Passwords does not match');
    } else {
      try {
        if (loading) {
          return;
        }
        setLoading((s) => !s);
        AuthService('/users/password-reset/set-password/', { email: username, password1: formState.password, password2: formState.confirmpassword }, (response) => {
          if (response && response.success) {
            nav('/');
            toast.success('Password Updated');
          } else {
            if (response && response.error && response.error.response && response.error.response.data && response.error.response.data.non_field_errors && response.error.response.data.non_field_errors.length > 0) {
              toast.error(`Reset failed. ${response.error.response.data.non_field_errors[0]}`)
            } else {
              toast.error('Reset failed!');
            }
          }
        })
      } catch (error) {
        if (error.message === 'Attempt limit exceeded, please try after some time.') {
          toast.error('Attempt limit exceeded, please try after some time.');
        } else {
          toast.error(error.message);
        }
      }
      setLoading((s) => !s);
    }
  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={(e) => e.preventDefault()}>
            <div className='LogIn_HeadingDiv'>
              <h2>New Password</h2>
              <p>To continue, please enter the new password and confirm password</p>
            </div>
            <div className='LogIn_InputDiv'>
              <FormControl fullWidth className='mb-1'>
                <label for="Password" style={{ display: "inline-flex" }}>
                  Password <span className='text-danger'>*</span>
                </label>
                <div className='LogIn_InputPasswordDiv'>
                  <input
                    className='LogIn_InputPassword'
                    required
                    value={formState.password}
                    onChange={(e) => {
                      setFormState((f) => ({ ...f, password: e.target.value }));
                    }}
                    type={!showPassword ? 'password' : 'text'}
                    placeholder='Enter Password'
                  />
                  <div>
                    {showPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </div>
                </div>
              </FormControl>

              <FormControl fullWidth className='mb-1'>
                <label for="Password" style={{ display: "inline-flex" }}>
                  Confirm Password <span className='text-danger'>*</span>
                </label>
                <div className='LogIn_InputPasswordDiv'>
                  <input
                    className='LogIn_InputPassword'
                    required
                    value={formState.confirmpassword}
                    onChange={(e) => {
                      setFormState((f) => ({ ...f, confirmpassword: e.target.value }));
                    }}
                    type={!showConfirmPassword ? 'password' : 'text'}
                    placeholder='Enter Confirm Password'
                  />
                  <div>
                    {showConfirmPassword ? (
                      <VisibilityOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      />
                    ) : (
                      <VisibilityOffOutlinedIcon
                        style={{ color: '#818181', cursor: 'pointer' }}
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      />
                    )}
                  </div>
                </div>
              </FormControl>
            </div>

            <div className='LogIn_BtnDiv' onClick={confirmPassword}>
              <button className='LogIn_Btn'>
                {!loading ? <div> Submit</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ConfirmPassword;
