/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthService } from '../../services/auth';
import { FormControl } from '@mui/material';

function DeleteAccount() {

  const nav = useNavigate();
  
  let [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    email: '',
  });

  async function handleDelete() {
    let regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

    if (formState.email === '') {
      toast.error('Email cannot be empty');
    } else if (regex.test(formState.email) === false) {
      toast.error('Invalid Email');
    } else {
      
      setLoading(true);
      try {
        AuthService('/users/delete-user/send-code/', { email: formState.email }, (response) => {
          setLoading(false);
          if (response && response.success) {
            nav('/account/delete/code', { state: { username: formState.email } });
          } else {
            if (response && response.error && response.error.data && response.error.data.detail) {
              toast.error(`${response && response.error && response.error.data.detail}`)
            } else if (response && response.error && response.error.data && response.error.data.message) {
                toast.error(response.error.data.message)
            } else {
              toast.error('Delete account failed!');
            }
          }
        });
      } catch (error) {
        if (
          error.message ===
          'Cannot reset password for the user as there is no registered/verified email or phone_number'
        ) {
          toast.error('User not registered');
        } else toast.error(error.message);

        console.log('error signing up', error);
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div className='LogInContainer'>
        <div className='LogIn_ImageDiv'>
          <img className='LogIn_Image' src={QR_BG} />
        </div>
        <div className='LogIn_RightDiv'>
          <form className='LogIn_FormDiv' onSubmit={(e) => e.preventDefault()}>
            <div className='LogIn_HeadingDiv'>
              <h2>Delete Account?</h2>
              <p>
                Please Enter the mail associated with your account and we will send you a link to
                delete the account.
              </p>
            </div>
            <div className='LogIn_InputDiv'>
              <FormControl fullWidth>
                <label for="Email" style={{ display: "inline-flex" }}>
                  Email <span className='text-danger'>*</span>
                </label>
                <input
                  className='LogIn_InputEmail'
                  value={formState.email}
                  onChange={(e) => {
                    setFormState((s) => ({ ...s, email: e.target.value }));
                  }}
                  type='email'
                  placeholder='Enter Email '
                />
              </FormControl>
            </div>
            <div className='LogIn_BtnDiv mt-4' onClick={handleDelete}>
              <button className='LogIn_Btn'>
                {!loading ? <div>Submit</div> : <PulseLoader size={15} color='#ffffff' />}
              </button>
            </div>
            <div className='LogIn_SignUpDiv'>
              <p>
                Back to {' '}
                <Link to='/' style={{ color: '#0A3F74', fontWeight: '700' }}>
                  Sign In
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DeleteAccount;
