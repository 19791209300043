/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../StoreContext';
import { CheckCircle, CheckCircleOutline, CheckCircleOutlineOutlined, InfoOutlined } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

function TopNavbar() {

  const { user, loggedIn } = Store();
  const nav = useNavigate();

  const checkIfUserIsSecured = () => {
    if (user?.is_honorary) {
        return true
    } else if (user?.is_flyer_activated) {
        return false
    } else if (user?.is_trial) {
        return false
    } else if (user?.subscription_plan == null) {
        return false
    } else {
        return true
    }
}

  return (
    <>
      <Stack className='container' sx={{ width: '100%' }} spacing={2}>
        {(loggedIn === true && checkIfUserIsSecured() === false) &&
          <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Alert severity="error" style={{ color: '#d32f2f', alignItems: 'center', justifyContent: 'center', fontSize: '1.2rem' }}><>You are not secured. <span onClick={() => nav('/subscription')} style={{ cursor: 'pointer', color: '#0a3f74', textDecoration: 'underline' }}>Act Now!</span></></Alert>
          </div>
        }
      </Stack>
    </>
  );
}

export default TopNavbar;
