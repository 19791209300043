import Navbar from '../common/Navbar'
import React, { useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Button, Card, CardContent, Grid, Typography, Box } from '@mui/material';
import HonoraryAccessModal from '../common/HonararyAccessModal';
import { Store as ContextStore } from '../../StoreContext';
import Axios from '../../config/axios';

import moment from 'moment-timezone'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const Subscription = () => {

    const { user } = ContextStore();

    const [alignment, setAlignment] = useState('yearly')
    const [plans, setPlans] = useState([])
    const [access, setAccess] = useState(false)
    const [applied, setApplied] = useState(false)
    const [hideButton, setHideButton] = useState(true)
    const [approved, setApproved] = useState(false)

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const yearlyPlan = [
        {
            plan: 'Standard (Yearly)',
            price: '₹5,900.00/year',
            para: '(Effectively ₹491.67/month when billed annually)',
            qrtag: '2',
            discount: '44.76%'
        },
        {
            plan: 'Premium (Yearly)',
            price: '₹9,900.00/year',
            para: '(Effectively ₹825.00/month when billed annually)',
            qrtag: '5',
            discount: '45.00%'
        }
    ]

    const monthlyPlan = [
        {
            plan: 'Standard (Monthly)',
            price: '₹590.00/month',
            para: '(Effectively ₹590/month when billed monthly)',
            qrtag: '2',
            discount: '44.76%'
        },
        {
            plan: 'Premium (Monthly)',
            price: '₹990.00/month',
            para: '(Effectively ₹990/month when billed monthly)',
            qrtag: '5',
            discount: '45.00%'
        },
    ]

    useEffect(() => {
        setPlans(yearlyPlan)
    }, [])

    const localTimeZone = (dateTime) => {
        const utcDate = moment.utc(dateTime)
        const localTimezone = moment.tz.guess()
        const localDate = utcDate.tz(localTimezone)
        return localDate.format('YYYY-MM-DD h:mm A')
    }

    const handleHide = (date, days) => {
        const declinedDate = new Date(localTimeZone(date));
        const currentDate = new Date();

        const diffTime = currentDate - declinedDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        const dayCount = days ? days : 30

        if (diffDays < dayCount) {
            setHideButton(true);
        } else {
            setHideButton(false);
        }
    }

    useEffect(() => {

        if (user) {
            const looser = user?.looser;
            if (user.is_honorary === true) {
                setApproved(true)
                setHideButton(true)
            } else if (looser && looser?.access_request && looser?.access_request === "P") {
                setApplied(true)
            } else if (looser && looser?.access_request && looser?.access_request === "A") {
                setHideButton(true)
                setApproved(true)
            } else if (looser && looser?.access_request && looser.access_request === "D" && looser.declined_at) {
                handleHide(looser.declined_at, looser?.honorary_request_waiting_period)
            } else if (looser && looser?.access_request && looser.access_request === "R" && looser.revoked_at) {
                handleHide(looser.revoked_at, looser?.honorary_request_waiting_period)
            } else if (user.is_honorary === false) {
                setHideButton(false)
            }
        }
    }, [user]);


    const checkIfUserIsSecured = () => {
        if (user?.is_honorary) {
            return true
        } else if (user?.is_flyer_activated) {
            return false
        } else if (user?.is_trial) {
            return false
        } else if (user?.subscription_plan == null) {
            return false
        } else {
            return true
        }
    }

    return (
        <>
            <Navbar />

            <Stack className='container' sx={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }} spacing={2}>
                {(checkIfUserIsSecured() === true) &&
                    <Alert severity="success" style={{ color: '#2e7d32', alignItems: 'center', justifyContent: 'center', fontSize: '1.2rem' }}>You are secured</Alert>
                }
            </Stack>

            <div className='container my-5'>
                <Grid container display="flex" alignItems="center" justifyContent="space-between">

                    <Grid item xs={12} sm={5} display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'flex-start' }} className='mb-3'>
                        <h3
                            style={{
                                fontWeight: 600,
                                whiteSpace: 'nowrap'
                            }}>
                            All Subscriptions
                        </h3>
                    </Grid>
                    <Grid item xs={12} sm={3} display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'flex-start' }} className='mb-3'>
                        {(applied) ?
                            <Button
                                variant="outlined"
                                sx={{
                                    width: { xs: 'auto', sm: '100%' },
                                    height: { xs: '0', sm: '56px', lg: '42px' },
                                    borderColor: '#969696',
                                    borderRadius: '30px',
                                    backgroundColor: '#969696',
                                    color: '#fff !important',
                                }}
                                disabled={applied}
                            >
                                Applied For Honorary Access
                            </Button>
                            : <>
                                {(!hideButton) &&
                                    <Button onClick={() => setAccess(true)}
                                        variant="outlined"
                                        sx={{
                                            width: { xs: 'auto', sm: '100%' },
                                            height: { xs: '0', sm: '56px', lg: '42px' },
                                            borderColor: '#0a3f74',
                                            borderRadius: '30px',
                                            backgroundColor: '#0a3f74',
                                            color: '#fff',
                                            '&:hover': {
                                                borderColor: '#0a3f74',
                                                color: '#fff',
                                                backgroundColor: '#0a3f74',
                                            },
                                        }}
                                        disabled={applied}
                                    >
                                        Apply For Honorary Access
                                    </Button>
                                }
                            </>}
                    </Grid>

                    <Grid item xs={12} sm={4} className='pt-3 pt-md-0 pt-lg-0' display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'end' }} >
                        <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="monthly-yearly toggle"
                            sx={{ marginLeft: { xs: 0, sm: 0, lg: 12 }, mb: { xs: 4, sm: 0 } }}
                        >
                            <ToggleButton onClick={() => setPlans(monthlyPlan)} value="monthly" aria-label="monthly" sx={{ px: 3, py: 1 }}>
                                Monthly
                            </ToggleButton>
                            <ToggleButton onClick={() => setPlans(yearlyPlan)} value="yearly" aria-label="yearly" sx={{ px: 3, py: 1 }}>
                                Yearly
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                </Grid>

                {(approved) &&
                    <Grid container display="flex" alignItems="center" justifyContent="center">
                        <Grid item xs={12} sm={12} sx={{ mx: { xs: '5%', sm: 0 } }}>
                            <Grid sx={{
                                display: 'flex',
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: 'center',
                                background: '#bdc7f1',
                                padding: '1rem 2rem',
                                borderRadius: '10px'
                            }} className='mt-4 mb-4'>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row" },
                                        alignItems: "center",
                                        justifyContent: { xs: "center", sm: "flex-start" },
                                        marginRight: '10px',
                                        marginBottom: { xs: "16px", sm: 0 }
                                    }}
                                >
                                    <CheckCircleIcon sx={{ color: "green", fontSize: 65 }} />
                                </Box>

                                <Typography
                                    sx={{
                                        textAlign: { xs: 'center', sm: 'left' },
                                    }}
                                >
                                    <h5 style={{ color: '#0a3f74', fontWeight: 'bold' }}>Honorary Subscription Activated</h5>
                                    <p className='mb-0'> Your honorary subscription for Standard Package is Active for One Year.</p>
                                </Typography>

                            </Grid>
                        </Grid>
                    </Grid>
                }

                <Grid container spacing={3} justifyContent="center" sx={{ marginBottom: "5%", marginTop: { xs: "1%", sm: "1%", lg: "1%" } }} >

                    {plans.map((item, index) => (
                        <Grid item xs={12} sm={12} md={12}
                            sx={{ mx: { xs: '5%', sm: 0 } }}>
                            <Card sx={{ boxShadow: 3, p: 2, marginBottom: "3%", borderRadius: '20px' }}>
                                <CardContent>

                                    <Grid container spacing={2} justifyContent="center" alignItems="center" direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}>

                                        <Grid item xs={12} sm={8} md={9} >
                                            <Typography variant="body1" >
                                                <span style={{ fontWeight: 'bold' }}>Plan:</span> {item.plan}
                                            </Typography>
                                            <Typography variant="body1" >
                                                <span style={{ fontWeight: 'bold' }}>Price:</span> {item.price}
                                            </Typography>
                                            <Typography variant="body1" >
                                                {item.para}
                                            </Typography>
                                            <Typography variant="body1" sx={{ mt: 1, fontWeight: 'bold' }}>{item.qrtag} QR Tag</Typography>
                                        </Grid>

                                        <Grid
                                            item xs={12} sm={4} md={3}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: { xs: 'row', sm: 'column' },
                                                justifyContent: { xs: 'center', sm: 'flex-start' },
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                sx={{
                                                    borderRadius: '15px',
                                                    backgroundColor: '#00D100',
                                                    color: '#fff',
                                                    padding: '2px 10px',
                                                    textAlign: 'center',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    width: { xs: 'auto', sm: '100%' },
                                                    mr: { xs: 2, sm: 0 }
                                                }}
                                            >
                                                {item.discount} discount
                                            </Typography>

                                            <Button
                                                variant="outlined"
                                                sx={{
                                                    mt: { xs: 0, sm: 3 },
                                                    width: { xs: 'auto', sm: '100%' },
                                                    height: '50px',
                                                    borderColor: '#0a3f74',
                                                    borderRadius: '30px',
                                                    backgroundColor: '#0a3f74',
                                                    color: '#fff',
                                                    '&:hover': {
                                                        borderColor: '#0a3f74',
                                                        color: '#fff',
                                                        backgroundColor: '#0a3f74',
                                                    },
                                                }}
                                            >
                                                Purchase
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}

                </Grid>
            </div>

            {access && (
                <HonoraryAccessModal
                    open={access}
                    setOpen={setAccess}
                    setApplied={setApplied}
                />
            )}
        </>
    )
}
export default Subscription

