import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './css/App.css';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Axios from '../config/axios';
import FilePicker from './FilePicker';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -38%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "10px",
    width: "40%",
    p: 4,
    overflow: 'auto'
};


const EditModal = ({ handleClose, open, singleData, handleSingleData }) => {
    const [data, setData] = useState({
        itemName: '',
        manufacturer: '',
        serialNo: '',
        estimated: '',
        description: '',
        photos: []
    })

    const [photosDeleted, setPhotosDeleted] = useState([]);
    const [loading, setLoading] = React.useState(false);

    const uploadInputRefs = useRef([React.createRef(), React.createRef(), React.createRef()]);

    useEffect(() => {
        if (singleData && open) {
            setData({
                itemName: singleData?.name,
                manufacturer: singleData?.manufacturer,
                serialNo: singleData?.serial_no,
                estimated: singleData?.estimated_value,
                description: singleData?.description,
                photos: singleData?.photos?.map((item) => item.photo),
                images: singleData?.photos,
            })

        }
    }, [singleData, open])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'estimated') {
            if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
                setData((prevData) => ({ ...prevData, [name]: value }));
            }
        } else {
            setData((prevData) => ({ ...prevData, [name]: value }));
        }
    };

    const handleFile = (e) => {
        e.stopPropagation();
        const files = e.target?.files;
        const images = [];

        for (let i = 0; i < files.length; i++) {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);  // Convert image to Base64
            reader.onloadend = () => {
                images.push(reader.result);  // Push the Base64 string
                setData((prevData) => ({
                    ...prevData,
                    photos: [...prevData.photos, ...images],
                }));
            };
        }
    };

    const handleClick = (index) => {
        uploadInputRefs.current[index].click();
    };

    const handleRemoveImage = (index) => {
        const removedImage = data.photos[index];

        const imageToDelete = singleData?.photos[index];

        if (imageToDelete && imageToDelete.id) {
            setPhotosDeleted(prev => [...prev, imageToDelete.id]);
        }

        setData((prevData) => ({
            ...prevData,
            photos: prevData.photos.filter((_, i) => i !== index),
        }));
    };


    const handleSaveEdit = () => {
        if (data.itemName === '') {
            toast.error('Item name cannot be empty');
        } else if (data.manufacturer === '') {
            toast.error('Item manufacturer cannot be empty');
        } else if (data.serialNo === '') {
            toast.error('Item serial number cannot be empty');
        } else if (data.estimated === '' || data.estimated == null || isNaN(parseFloat(data.estimated))) {
            toast.error('Estimated value must be a number and cannot be empty');
        } else if (data.description === '') {
            toast.error('Item description cannot be empty');
        } else if (data.photos && data.photos.length < 3) {
            toast.error('You need to upload 3 images');
        } else {
            if (loading) {
                return;
            }

            const estimatedValue = parseFloat(data.estimated).toFixed(2);

            const params = {
                name: data.itemName,
                manufacturer: data.manufacturer,
                serial_no: data.serialNo,
                estimated_value: estimatedValue,
                description: data.description,
                photos: [{ photo: data?.photos }],
                deleted_photos: photosDeleted,
            };

            const images = [];
            if (data?.photos && data?.photos.length > 0) {
                for (let i = 0; i < data?.photos.length; i++) {
                    if (!data?.photos[i].includes('/media/uploads/')) {
                        images.push({ photo: data?.photos[i] });
                    }
                }
            }

            params.photos = images;

            setLoading(true)

            Axios.patch(`/looser/items/${singleData?.id}/`, params)
                .then((res) => {
                    setLoading(false)
                    if (res) {
                        toast.success('Item details updated successfully!');
                        handleClose();
                        handleSingleData()
                    }
                })
                .catch((err) => {
                    toast.error('Update Failed!');
                    setLoading(false)
                });
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
            style={{ overflowY: 'auto' }}
        >
            <Box sx={style}>
                <div style={{ position: 'relative', width: '100%' }}>
                    <div
                        style={{
                            position: 'absolute',
                            right: '-5%',
                            marginTop: '-5%',
                            border: '2px solid #000',
                            borderRadius: '50%',
                            padding: '3px',
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </div>

                </div>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Update Item
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <div className='all_fields'>
                        {/* <p>Name</p> */}
                        <label for="name" style={{ display: "inline-flex", fontWeight: "bold" }}>
                            Name <span className='text-danger'>*</span>
                        </label>
                        <input className='input_field' onChange={handleInputChange} value={data?.itemName} name="itemName" type="text" />
                        {/* <p>Manufacturer</p> */}
                        <label for="manufacturer" style={{ display: "inline-flex", fontWeight: "bold" }}>
                            Manufacturer <span className='text-danger'>*</span>
                        </label>
                        <input className='input_field' onChange={handleInputChange} value={data?.manufacturer} name="manufacturer" type="text" />
                        {/* <p>Serial No</p> */}
                        <label for="serial" style={{ display: "inline-flex", fontWeight: "bold" }}>
                            Serial No<span className='text-danger'>*</span>
                        </label>
                        <input className='input_field' onChange={handleInputChange} value={data?.serialNo} name="serialNo" type="text" />

                        <label for="estimated" style={{ display: "inline-flex", fontWeight: "bold" }}>
                            Estimated Value<span className='text-danger'>*</span>
                        </label>
                        <input className='input_field' onChange={handleInputChange} value={data?.estimated} name="estimated" type="text" />
                        {/* <p>Description</p> */}
                        <label for="description" style={{ display: "inline-flex", fontWeight: "bold" }}>
                            Description<span className='text-danger'>*</span>
                        </label>
                        <textarea className='text_field' onChange={handleInputChange} value={data?.description} name="description" cols="50" rows="5"></textarea>

                        {/* <input
                            ref={uploadInputRef} accept="image/*" type="file" id='photo_file' style={{ display: 'none' }} />
                        <label htmlFor="photo_file" className='photo_upload'>
                            <AddOutlinedIcon sx={{ color: "rgb(10, 63, 116)" }} />
                        </label> */}
                        {/* <FilePicker onChange={handleFile} fileName={data.photos} /> */}


                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                            {data.photos.map((photo, index) => (
                                <div
                                    key={index}
                                    style={{
                                        width: 70,
                                        height: 70,
                                        borderRadius: '10%',
                                        backgroundColor: '#f5f5f5',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        marginRight: '10px',
                                        marginTop: '15px',
                                        border: '1px solid rgb(223, 223, 223)',
                                    }}
                                >
                                    <img
                                        src={photo}
                                        alt={`Selected ${index}`}
                                        style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                                    />
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '-8px',
                                            right: '-8px',
                                            border: '2px solid #ff0000',
                                            borderRadius: '50%',
                                            padding: '3px',
                                            backgroundColor: '#ff0000',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => handleRemoveImage(index)}
                                    >
                                        <CloseIcon style={{ width: '13px', height: '13px', color: 'white' }} />
                                    </div>
                                </div>
                            ))}

                            {Array.from({ length: 3 - data.photos.length }).map((_, index) => (
                                <div
                                    key={`upload-${index}`}
                                    style={{
                                        width: 70,
                                        height: 70,
                                        borderRadius: '10%',
                                        backgroundColor: '#f5f5f5',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'relative',
                                        marginRight: '10px',
                                        marginTop: '15px',
                                        border: '1px solid rgb(223, 223, 223)',
                                    }}
                                    onClick={() => handleClick(index)}
                                >
                                    <AddOutlinedIcon
                                        style={{ width: '50%', height: '50%' }}
                                    />

                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        ref={(el) => (uploadInputRefs.current[index] = el)}
                                        onChange={(e) => handleFile(e, data.photos.length + index)}
                                    />
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={handleSaveEdit}
                            style={{
                                backgroundColor: "#0a3f74"
                            }}
                            className='found_btn'>
                            {!loading ? (
                                <div>Save</div>
                            ) : (
                                <PulseLoader size={15} color='#ffffff' />
                            )}
                        </button>
                    </div>
                </Typography>
            </Box>
        </Modal>
    )
}

export default EditModal