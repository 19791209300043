import React, { useEffect, useState } from 'react';
import Navbar from '../common/Navbar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ErrorIcon from '@mui/icons-material/Error';
import { GetMyQRTags, SendQRCode } from '../../services/user';
import { Store as ContextStore, UpdateStore } from '../../StoreContext';
import { toast } from 'react-toastify';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { BarLoader, BeatLoader, DotLoader, FadeLoader } from 'react-spinners'
import TopNavbar from '../common/TopNavContent';

const MyQRTags = () => {

    const [qrtags, setQRTags] = React.useState([])
    const [alignment, setAlignment] = useState('used');
    const [isUsed, setIsUsed] = useState(true);
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = React.useState();

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    const getQRTagList = () => {
        try {
            setLoading(true)
            GetMyQRTags((response) => {
                setLoading(false)
                if (response && response.success) {
                    const usedQRTags = response.data.filter(item => item.is_used === true);
                    setQRTags(usedQRTags)
                    setAllData(response.data)
                } else {
                    setAllData([])
                    setQRTags([])
                }
            });
        } catch (error) {
            console.log(false);
        }
    }

    useEffect(() => {
        const res = allData.filter(item => item.is_used === isUsed);
        setQRTags(res)
    }, [isUsed])

    React.useEffect(() => {
        getQRTagList()
    }, [])

    function handleDownload(imageUrl, imageName) {
        fetch(imageUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = imageName; // Set the name for the downloaded image
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((e) => console.log(e));
    }

    function handleEmail(item) {
        try {
            SendQRCode({ id: item?.id }, (response) => {
                if (response && response.success) {
                    toast.success('QR sent to your registered email!');
                } else {
                    toast.error(`Failed to send email!`)
                }
            });
        } catch (error) {
            toast.error(`Failed to send email!`)
        }
    }

    return (
        <div>
            <Navbar />
            <TopNavbar />
            <div className='container my-5'>
                <Grid container alignItems="center" justifyContent="space-between" >

                    <Grid item xs={12} sm={6} display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'start' }}>
                        <h3
                            style={{
                                fontWeight: 600,
                                whiteSpace: 'nowrap',
                            }}>
                            My QR Tags
                        </h3>
                    </Grid>

                    <Grid item xs={12} sm={6} className='pt-3 pt-md-0 pt-lg-0' display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'end' }} >
                        <ToggleButtonGroup
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="used-unused toggle"
                        >
                            <ToggleButton onClick={() => setIsUsed(true)} value="used" aria-label="used" sx={{ px: 3, py: 1 }}>
                                Used
                            </ToggleButton>
                            <ToggleButton onClick={() => setIsUsed(false)} value="unused" aria-label="unused" sx={{ px: 3, py: 1 }}>
                                Unused
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>

                <div className='pb-5 pb-md-0 mt-5' sx={{ display: 'flex' }}>

                    {(loading) ? <>
                        <div className='my-4 text-center'><BeatLoader /></div>
                    </> : <>
                        {(qrtags && qrtags.length > 0) ? <>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                {(qrtags.map((item) => (<>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Card sx={{
                                            margin: 'auto', boxShadow: 3, marginBottom: "5%", borderRadius: '15px', width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
                                            maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }
                                        }}>
                                            <CardContent style={{ paddingBottom: '0px' }}>
                                                <Grid container spacing={2} alignItems="center" >
                                                    <Grid item xs={12} sm={4} md={3} className="qr-card" sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px' }} >
                                                        <CardMedia
                                                            component="img"
                                                            alt="QR Logo"
                                                            image={item.qr_code}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} sm={8} md={9} container spacing={1} direction="column"
                                                        className='mt-3 mt-lg-0 mb-5 mb-lg-0'
                                                        sx={{
                                                            textAlign: { xs: 'center', sm: 'left' },
                                                            justifyContent: { xs: 'center', sm: 'flex-start' },
                                                            alignItems: { xs: 'center', sm: 'flex-start' },
                                                        }}>
                                                        {(item.is_used) && <>
                                                            <Typography gutterBottom variant="h5" component="div" style={{ fontWeight: 'bold' }}>
                                                                {item.item_details?.name} 
                                                            </Typography>
                                                            <Typography gutterBottom variant="p" component="div" style={{ marginBottom: "10%" }}>
                                                                {item.item_details?.description}
                                                            </Typography>
                                                        </>}

                                                        <Grid
                                                            container
                                                            spacing={2}
                                                            sx={{
                                                                flexDirection: { xs: 'column', sm: 'row' },
                                                                padding: { xs: '5%', sm: 0 },
                                                                marginLeft: { xs: '-13px', sm: '-13px' }
                                                            }}

                                                        >
                                                            {(item.is_used) && <>
                                                                {(item.item_details?.status === 'found') ? (
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            sx={{
                                                                                borderRadius: '30px',
                                                                                fontSize: '0.8rem',
                                                                                width: '100%',
                                                                                height: '40px',
                                                                                backgroundColor: '#00D100',
                                                                                border: '2px solid #00D100',
                                                                                '&:hover': {
                                                                                    borderColor: '#00D100',
                                                                                    color: '#fff',
                                                                                    backgroundColor: '#00D100',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <ErrorIcon sx={{ fontSize: 16 }} className='mx-1' />
                                                                            {item.item_details?.status}
                                                                        </Button>
                                                                    </Grid>
                                                                ) : (
                                                                    <Grid item xs={12} sm={4}  >
                                                                        <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            sx={{
                                                                                borderRadius: '30px',
                                                                                fontSize: '0.8rem',
                                                                                width: '100%',
                                                                                height: '40px',
                                                                                backgroundColor: '#FF0000',
                                                                                border: '2px solid #FF0000',
                                                                                '&:hover': {
                                                                                    borderColor: '#FF0000',
                                                                                    color: '#fff',
                                                                                    backgroundColor: '#FF0000',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <ErrorIcon sx={{ fontSize: 16 }} className='mx-1' />
                                                                            {item.item_details?.status}
                                                                        </Button>
                                                                    </Grid>
                                                                )}
                                                            </>}

                                                            <Grid item xs={12} sm={4}>
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        fontSize: '0.8rem',
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        borderColor: '#0a3f74',
                                                                        borderRadius: '30px',
                                                                        backgroundColor: '#0a3f74',
                                                                        color: '#fff',
                                                                        '&:hover': {
                                                                            borderColor: '#0a3f74',
                                                                            color: '#fff',
                                                                            backgroundColor: '#0a3f74',
                                                                        },
                                                                    }}
                                                                    onClick={() => handleDownload(item.qr_code, `${item.item_details?.name}.jpg`)}
                                                                >
                                                                    Download
                                                                    <FileDownloadOutlinedIcon sx={{ fontSize: 16 }} className='mx-1' />
                                                                </Button>
                                                            </Grid>

                                                            <Grid item xs={12} sm={4} >
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{
                                                                        fontSize: '0.8rem',
                                                                        width: '100%',
                                                                        height: '40px',
                                                                        borderColor: '#0a3f74',
                                                                        borderRadius: '30px',
                                                                        backgroundColor: '#0a3f74',
                                                                        color: '#fff',
                                                                        '&:hover': {
                                                                            borderColor: '#0a3f74',
                                                                            color: '#fff',
                                                                            backgroundColor: '#0a3f74',
                                                                        },
                                                                    }}
                                                                    onClick={() => handleEmail(item, `${item.item_details?.name}.jpg`)}
                                                                >
                                                                    Mail
                                                                    <MailOutlineIcon sx={{ fontSize: 16 }} className='mx-1' />
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </>)))}
                            </Grid>
                        </> : <>
                            <div className='all_Items d-flex justify-content-center'>
                                <p className='text-muted'>No Records Found!</p>
                            </div>
                        </>}
                    </>}
                </div>
            </div>
        </div >
    );
};

export default MyQRTags;
