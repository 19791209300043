import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import QrReader from 'react-qr-scanner'
import { InfoOutlined, QrCodeScanner } from '@mui/icons-material';
import { determineDeviceType } from '../../utils/functions';
import QRCodeCheck from '../../images/qr-code-pay.png';
import Axios from '../../config/axios';
import { useNavigate } from 'react-router-dom'
import ChatModal from './locationPopup'
import { Store } from '../../StoreContext';
import { BeatLoader } from 'react-spinners';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 480,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const previewStyle = {
    width: '100%',
    marginTop: '1rem'
}

export default function BasicModal({ setItemId, open, setOpen }) {

    const { user, loggedIn } = Store();
    const [loading, setLoading] = React.useState(false);
    const [start, setStart] = React.useState(false);
    const [exist, setExist] = React.useState(false);
    const [scanned, setScanned] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const [uuid, setUuid] = React.useState(null);

    const nav = useNavigate()

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const handleCheck = (uid) => {
        if (uid) {
            setLoading(true)
            Axios.post(`/looser/find-item/${uid}/`).then((response) => {
                setLoading(false)
                if (Object.values(response?.data || {}).length > 0) {
                    setExist(true)
                    setStart(false)
                    setItem(response?.data)
                } else if (Object.values(response?.data || {}).length === 0) {
                    setExist(false)
                    setStart(false)
                }
            })
        }
    }

    const handleScan = (data) => {
        if (data && data.text) {

            const res = data.text.split('/').pop()
            
            if (res) {
                setUuid(res)
                handleCheck(res)
                setScanned(true)
                return false
            }
        }
    }

    const handleError = (err) => {
        console.error(err)
        setOpen(false)
        setScanned(false)
        setExist(false)
    }

    const handleAdd = () => {
        setOpen(false)
        setItemId(uuid)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                disableEscapeKeyDown
                style={{ overflow: 'auto', paddingTop: '1rem', paddingBottom: '1rem' }}
            >
                <Box sx={style}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: '-5%',
                                marginTop: '-5%',
                                border: '2px solid #000',
                                borderRadius: '50%',
                                padding: '3px',
                                backgroundColor: '#fff',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            onClick={closeModal}
                        >
                            <CloseIcon />
                        </div>

                    </div>
                    <div
                        className='text-center'
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {(scanned) ? <>

                            {(loading) ? <>
                                <div style={{ marginTop: '2rem', marginBottom: '1rem' }}><BeatLoader /></div>
                            </> : <>

                                {(exist) ? <>

                                    <div style={{ marginTop: '2rem', marginBottom: '1rem', width: '90%' }}>

                                        <img style={{ width: '150px', marginBottom: '1rem' }} src={item && item?.thumbnail} />

                                        <div className='LostItem_DetailDiv mt-2'>
                                            {item && item?.name &&
                                                <p>
                                                    <b>Item Name:</b> {item?.name}
                                                </p>
                                            }
                                            {item && item?.manufacturer &&
                                                <p>
                                                    <b>Manufacturer: </b> {item?.manufacturer}
                                                </p>
                                            }
                                            {item && item.description &&
                                                <p>
                                                    <b>Description:</b> {item?.description}
                                                </p>
                                            }
                                        </div>

                                        {user && item && user.id !== item.user_id && (
                                            <div className='LostItem_ChatBtn'>
                                                <ChatModal data={item} btnText='Chat with owner' />
                                            </div>
                                        )}

                                    </div>

                                </> : <>

                                    <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>

                                        <img style={{ width: '150px' }} src={QRCodeCheck} />

                                        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                                            <b>Item not registered!</b>
                                        </p>

                                        <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                                            Your scan is completed. <br /> Now let's gather additional information about the item.
                                        </p>

                                        <Button
                                            variant="outlined"
                                            sx={{
                                                mt: 3,
                                                display: 'block',
                                                width: '100%',
                                                height: '50px',
                                                mx: 'auto',
                                                borderColor: '#0a3f74',
                                                borderRadius: '30px',
                                                backgroundColor: '#0a3f74',
                                                color: '#fff',
                                                '&:hover': {
                                                    borderColor: '#0a3f74',
                                                    color: '#fff',
                                                    backgroundColor: '#0a3f74',
                                                },
                                            }}
                                            onClick={() => handleAdd()}
                                        >
                                            Add Item
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                mt: 3,
                                                display: 'block',
                                                width: '100%',
                                                height: '50px',
                                                mx: 'auto',
                                                borderColor: '#0a3f74',
                                                borderRadius: '30px',
                                                backgroundColor: '#fff',
                                                color: '#0a3f74',
                                                '&:hover': {
                                                    borderColor: '#0a3f74',
                                                    color: '#0a3f74',
                                                    backgroundColor: '#fff',
                                                }
                                            }}
                                            onClick={() => {
                                                setOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </>}
                            </>}
                        </> : <>
                            {(start) ? <>
                                <QrReader
                                    delay={100}
                                    style={previewStyle}
                                    onError={handleError}
                                    onScan={handleScan}
                                    constraints={
                                        determineDeviceType === 'web'
                                        ? undefined
                                        : {
                                            video: {
                                                facingMode: { ideal: 'environment', fallback: 'user' }
                                            }
                                          }
                                    }
                                />
                            </> : <>
                                <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>

                                    <QrCodeScanner sx={{ fontSize: 150 }} />

                                    <p style={{ textAlign: 'center', marginTop: '1rem' }}>
                                        Align the QR code inside the frame while start scanning.
                                    </p>

                                    <p style={{ textAlign: 'center', marginTop: '1rem', fontSize: '14px', color: '#a5a5a5' }}>
                                        <InfoOutlined /> Make sure to allow camera access!
                                    </p>

                                    <Button
                                        variant="outlined"
                                        className='scanner-button'
                                        sx={{
                                            mt: 3,
                                            display: 'block',
                                            width: '100%',
                                            height: '50px',
                                            mx: 'auto',
                                            borderColor: '#0a3f74',
                                            borderRadius: '30px',
                                            backgroundColor: '#0a3f74',
                                            color: '#fff',
                                            '&:hover': {
                                                borderColor: '#0a3f74',
                                                color: '#fff',
                                                backgroundColor: '#0a3f74',
                                            },
                                        }}
                                        onClick={() => setStart(true)}
                                    >
                                        Start Scan
                                    </Button>
                                </div>
                            </>}

                        </>}

                    </div>
                </Box>
            </Modal>
        </div>
    );
}