/* eslint-disable jsx-a11y/alt-text */
import '../css/App.css';
import QR_BG from '../../images/login-bg.svg';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { UpdateStore } from '../../StoreContext';
import SocialLogin from '../common/SocialLogin';

// import { toast } from "react-toastify";
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { useEffect, useState, useCallback } from 'react';
import { PulseLoader } from 'react-spinners';
import { Link, useNavigate } from 'react-router-dom';
import { AuthLogin, AuthResendSignup, AuthResendActivation } from '../../services/auth';
import { FormControl } from '@mui/material';


function Login() {
    const nav = useNavigate();
    const updateStore = UpdateStore();
    const [showPassword, setShowPassword] = useState(false);
    // const [token, setToken] = useState(null)

    let [loading, setLoading] = useState();
    // const toast = toast({ position: toast.POSITION.BOTTOM_RIGHT });
    const [formState, setFormState] = useState({
        email: '',
        password: '',
    });

    const [isDeleted, setIsDeleted] = useState(false);
    const [accountDeletedMessage, setAccountDeletedMessage] = useState('');

    const handleReactivate = () => {
        resendActivationCode();
        nav('/reactivationcode', { state: { username: formState.email } });
    }

    const handleCancel = () => {
        setIsDeleted(false)
        setFormState({
            email: '',
            password: ''
        })
        window.scrollTo({ top: 0 })
    }

    async function resendConfirmationCode() {
        try {
            AuthResendSignup({ email: formState.email }, (response) => {
                if (response && response.success) {
                    // toast.success('Code resent successfully');
                } else {
                    // toast.error("Resend failed. Please try again later!")
                }
            });
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function resendActivationCode() {
        try {
            AuthResendActivation({ email: formState.email }, (response) => {
                if (response && response.success) {
                    // toast.success('Code resent successfully');
                } else {
                    // toast.error("Resend failed. Please try again later!")
                }
            });
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function signIn() {
        // if (!Object.values(formState).every(Boolean)) {
        //   toast.error('Fields cannot be empty', { position: toast.POSITION.BOTTOM_RIGHT });
        //   return;
        // }
        let regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;

        if (formState.email === '') {
            toast.error('Email cannot be empty');
        } else if (regex.test(formState.email) === false) {
            toast.error('Invalid Email');
        } else if (formState.password === '') {
            toast.error('Password cannot be empty');
        } else {
            if (loading) {
                return;
            }
            setLoading((s) => !s);
            try {
                // const user = await Auth.signIn(formState.email, formState.password);
                AuthLogin({ username: formState.email, password: formState.password }, (response) => {

                    setLoading((s) => !s);
                    if (response && response.success) {
                        if (response?.data && !response.data.user?.has_accepted_terms) {
                            toast.error(`Please agree to the Terms & Conditions!`)
                            nav('/acceptterms', { state: { username: formState.email } });
                        } else if (response.data && !response.data.user?.email_verified) {
                            toast.error(`Please verify your email account!`)
                            resendConfirmationCode()
                            nav('/verificationcode', { state: { username: formState.email } });
                        } else if (response.data && !response.data.user?.is_active) {
                            setIsDeleted(true)
                            setAccountDeletedMessage(response.data?.account_reactivation_message || 'It appears that your account was previously deleted. To reactivate your account, please verify your email address. A verification OTP will be sent to your registered email. Would you like to proceed with reactivation?');

                        } else if (response.data) {
                            localStorage.setItem('user', JSON.stringify(response?.data.user));
                            updateStore({ user: response?.data.user, loggedIn: true, accessToken: response?.data.access });
                            localStorage.setItem('userData', JSON.stringify(response?.data.user));
                            localStorage.setItem('accessToken', response?.data.access);
                            localStorage.setItem('refreshToken', response?.data.refresh);

                            if (sessionStorage.getItem('uuidcode')) {
                                nav(`/uuid/${sessionStorage.getItem('uuidcode')}`)
                            }
                        }
                    } else {
                        if (response && response.error && response.error.data && response.error.data.detail) {
                            toast.error(`${response && response.error && response.error.data.detail}`)
                        } else {
                            toast.error(`Invalid login credentials!`)
                        }
                    }
                });
                // const uid = localStorage.getItem('uid');
                // if (uid) {
                //   nav('/chat');
                // } else {
                //   nav('/');
                // }
                // setLoading((s) => !s);
                // toast.success('Login Successfully');
            } catch (error) {
                setLoading((s) => !s);

                if (error.message === 'User is not confirmed.') {
                    toast.error('User is not confirmed.');
                } else if (error.message === 'Incorrect username or password.') {
                    toast.error('Incorrect username or password.');
                } else {
                    toast.error(error.message);
                }
                console.log('error signing in', error.message);
            }
        }
    }
    console.log("deleted:", isDeleted)
    return (
        <>
            <div className='LogInContainer'>
                <div className='LogIn_ImageDiv'>
                    <img className='LogIn_Image' src={QR_BG} />
                </div>
                <div className='LogIn_RightDiv'>
                    {isDeleted ? (
                        <>
                            <form className='LogIn_FormDiv' >
                                <div className='LogIn_HeadingDiv'>
                                    <h3 style={{ textAlign: 'center', fontWeight: 600 }}>Account Deleted</h3>
                                    <p className='pt-3' style={{ textAlign: 'center' }}>
                                        {accountDeletedMessage}
                                    </p>
                                </div>

                                <div className='LogIn_BtnDiv'>
                                    <button onClick={handleReactivate} className='LogIn_Btn'>
                                        <div> Reactivate</div>
                                    </button>
                                </div>
                                <div className='LogIn_SignUpDiv' >
                                    <p>
                                        <Link style={{ color: '#000', fontWeight: '700' }} onClick={handleCancel}>Cancel</Link>
                                    </p>
                                </div>
                            </form>
                        </>

                    ) : (

                        <>

                            <form className='LogIn_FormDiv' onSubmit={(e) => e.preventDefault()}>
                                <div className='LogIn_HeadingDiv'>
                                    <h2>Hello,</h2>
                                    <p>let’s help find your lost item</p>
                                </div>
                                <div className='LogIn_InputDiv'>
                                    <h3 className='pb-4'>Login</h3>

                                    <FormControl fullWidth className='mb-1'>
                                        <label for="Email" style={{ display: "inline-flex" }}>
                                            Email <span className='text-danger'>*</span>
                                        </label>
                                        <input
                                            className='LogIn_InputEmail'
                                            // required
                                            value={formState.email}
                                            onChange={(e) => {
                                                setFormState((s) => ({ ...s, email: e.target.value }));
                                            }}
                                            type='email'
                                            placeholder='Enter Email'
                                        />
                                    </FormControl>
                                    <FormControl fullWidth className='mb-1'>
                                        <label for="Password" style={{ display: "inline-flex" }}>
                                            Password <span className='text-danger'>*</span>
                                        </label>
                                        <div className='LogIn_InputPasswordDiv'>
                                            <input
                                                className='LogIn_InputPassword'
                                                // required
                                                value={formState.password}
                                                onChange={(e) => {
                                                    setFormState((f) => ({ ...f, password: e.target.value }));
                                                }}
                                                type={!showPassword ? 'password' : 'text'}
                                                placeholder='Enter Password'
                                            />


                                            <div>
                                                {showPassword ? (
                                                    <VisibilityOutlinedIcon
                                                        style={{ color: '#818181', cursor: 'pointer' }}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    />
                                                ) : (
                                                    <VisibilityOffOutlinedIcon
                                                        style={{ color: '#818181', cursor: 'pointer' }}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    />
                                                )}

                                            </div>

                                        </div>
                                    </FormControl>

                                </div>
                                <div className='LogIn_ForgotPasswordDiv'>
                                    <Link to='forgetpassword' className='LogIn_ForgotPasswordLink'>
                                        Forgot Password?
                                    </Link>
                                </div>
                                <div className='LogIn_BtnDiv' onClick={signIn}>
                                    <button className='LogIn_Btn'>
                                        {!loading ? (
                                            <div> Sign In</div>
                                        ) : (
                                            <PulseLoader size={15} color='#ffffff' />
                                        )}
                                    </button>
                                </div>
                                <div className='LogIn_SignUpDiv'>
                                    <p>
                                        Don’t have an account?{' '}
                                        <Link
                                            to='/signup'
                                            style={{ color: '#0A3F74', fontWeight: '600' }}
                                        >
                                            Sign up
                                        </Link>
                                    </p>
                                </div>
                                <div className='LogIn_DividerDiv'>
                                    <div className='LogIn_Divider' />
                                    <p>OR</p>
                                    <div className='LogIn_Divider' />
                                </div>

                                <div className='LogIn_SignInOptions'>
                                    <p>Sign in with</p>
                                </div>
                                <SocialLogin />

                            </form>

                        </>
                    )}
                </div >
            </div >
        </>
    );
}

export default Login;
