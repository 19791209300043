/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import '../css/App.css';
import Navbar from '../common/Navbar';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { Button, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import DaveImg from '../../images/dave.png'
import TopNavbar from '../common/TopNavContent';

function OurStory() {
  const location = useLocation();
  useEffect(() => {
    mixpanel.track('Our Story', {
      pageName: "Our Story"
    });
  }, [])

  return (
    <>
      <Navbar />
      <TopNavbar />
      <div className='About_Container container my-5'>
        {/* <h3>Our Story</h3>
         */}
        <div className='d-flex flex-column'>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ width: '100%', margin: 'auto' }}>
            <Card sx={{
              margin: 'auto', boxShadow: 'none', marginTop: "2%", width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },
              maxWidth: { xs: '100%', sm: '100%', md: '100%', lg: '100%' }
            }}>
              <CardContent style={{ padding: '20px' }}>
                <Grid container alignItems="center" >
                  <Grid item xs={12} sm={12} md={3} display="flex" justifyContent={{ xs: 'center', sm: 'center', lg: 'flex-start' }}>
                    <img src={DaveImg} style={{ width: '80%', borderRadius: '10px' }} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={9} display="flex" flexDirection="column" alignItems={{ xs: 'center', sm: 'center', lg: 'flex-start' }} justifyContent={{ xs: 'center', sm: 'center', lg: 'flex-start' }} marginTop={{ xs: '2rem', sm: '2rem', lg: '0rem' }}>
                    <h3 className='mb-3'><b>About Dave</b></h3>
                    <Typography sx={{ textAlign: { xs: 'center', sm: 'center', md: 'left', lg: 'left' } }} style={{ fontSize: '1.2rem' }} component='p' variant='p'>
                      Hi, I'm Dave, a retired Army Major and Japanese linguist. I served for 21 years, blending my expertise in psychology, nursing, and encryption to create a product that will improve your life. <br />My passion for codes and communication inspired me to create QRTag.it, a unique innovation that enables you to take charge of your personal information and safeguard your valuables, while meeting your environmental sustainment goals. I invite you to join me on this journey.
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </div>
      </div>
    </>
  );
}

export default OurStory;
