import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { ApplyCouponCode } from '../../services/user';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { Store, UpdateStore } from '../../StoreContext';
import { GetUserProfile } from '../../services/user';

import GiftBox from '../../images/gift-box-svgrepo-com.svg'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

export default function BasicModal({ open, setOpen }) {

    const { user, messages } = Store();
    const updateStore = UpdateStore();
   
    let [loading, setLoading] = React.useState(false);
    let [couponCode, setCouponCode] = React.useState(null);
    let [flyerMessage, setFlyerMessage] = React.useState(null);

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }
        setOpen(false);
    };

    const closeModal = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        if (messages) {
            const res = messages.filter((item) => item.key === 'flyer_message')
            if (res) {
                setFlyerMessage(res[0])
            }
        }
    }, [messages])

    const getProfile = () => {
        try {
            GetUserProfile((response) => {
                if (response && response.success) {
                    if (response?.data) {
                        const temp = { ...user }
                        temp['is_flyer_activated'] = true;
                        temp['subscription_plan'] = response?.data?.subscription_plan || null;
                        localStorage.setItem('user', JSON.stringify(response?.data));
                        updateStore({ user: temp });
                        localStorage.setItem('userData', JSON.stringify(response?.data));
                    }
                }
            });
        } catch (error) {
            console.log(false);
        }
    }

    const submitCouponCode = () => {
        if (couponCode === '' || couponCode === null) {
            toast.error('Coupon code cannot be empty!');
        } else {
            if (loading) {
                return;
            }
            
            setLoading(true);
            try {
                ApplyCouponCode({ coupon: couponCode }, (response) => {
                    setLoading(false);
                    if (response && response.success) {
                        setOpen(false)
                        setCouponCode(null)
                        getProfile()
                        toast.success('Coupon code applied successfully!');
                    } else {
                        if (response && response.error && response.error.data && response.error.data.detail) {
                            toast.error(`${response && response.error && response.error.data.detail}`)
                        } else if (response && response.error && response.error.data && response.error.data.message) {
                            toast.error(response.error.data.message)
                        } else {
                            toast.error(`Failed to apply coupon code!`)
                        }
                    }
                });
            } catch (error) {
                setLoading(false);
            }
        }
    }

    const hidepopup = () => {
        let date = new Date();
        date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
        document.cookie = `${'modalShown'}=${true}; expires=${date.toUTCString()}; path=/`;
        setOpen(false);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <div
                                style={{
                                    position: 'absolute',
                                    right: '-5%',
                                    marginTop: '-5%',
                                    border: '2px solid #000',
                                    borderRadius: '50%',
                                    padding: '3px',
                                    backgroundColor: '#fff',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={closeModal}
                            >
                                <CloseIcon />
                            </div>

                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                top: '-85px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '100px',
                                height: '100px',
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <img
                                src={GiftBox}
                                alt="Gift Box"
                                style={{
                                    width: '70px',
                                    height: '70px',
                                }}
                            />
                        </div>
                    </div>
                    <div className='text-center' style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>

                        <Typography sx={{ marginTop: '5%' }} id='modal-modal-title' variant='h5' component='h2' style={{ fontWeight: 'bold' }} className='mt-5'>
                            Special Offer Just for You!
                        </Typography>
                        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                            {(flyerMessage && flyerMessage?.value) ? <>{flyerMessage?.value}</> : 'Please enter your coupon code to receive a ONE MONTH FREE subscription.'}
                        </Typography>

                        <label for="Message" className='mt-4' style={{ width: '100%', display: 'flex' }}>
                            Coupon Code <span className='text-danger'>*</span>
                        </label>

                        <input
                            className='LogIn_InputEmail'
                            type="text"
                            id="coupon"
                            name="coupon"
                            placeholder='Enter Coupon Code'
                            style={{
                                width: '100%',
                                height: '50px',
                                padding: '10px',
                            }}
                            value={couponCode}
                            onChange={(e) => setCouponCode(e.target.value)}
                        />

                        <Button
                            variant="outlined"
                            sx={{
                                my: 2,
                                display: 'block',
                                width: '100%',
                                height: '50px',
                                mx: 'auto',
                                borderColor: '#0a3f74',
                                borderRadius: '30px',
                                backgroundColor: '#0a3f74',
                                color: '#fff',
                                '&:hover': {
                                    borderColor: '#0a3f74',
                                    color: '#fff',
                                    backgroundColor: '#0a3f74',
                                },
                            }}
                            onClick={submitCouponCode}
                        >
                            {!loading ? (
                                <div>Apply</div>
                            ) : (
                                <PulseLoader size={15} color='#ffffff' />
                            )}
                        </Button>

                        <a href="javascript:void(0)" onClick={() => hidepopup()}>Please don’t show this again</a>

                    </div>
                </Box>
            </Modal>
        </div>
    );
}
